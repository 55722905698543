import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import $ from 'jquery'; // Import jQuery if installed via npm

const NavMenu = () => {
    
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();
    const [menuData, setMenuData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeCategory, setActiveCategory] = useState('');
    const [activeSubCategory, setActiveSubCategory] = useState('');
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [showShopMenu, setShowShopMenu] = useState(false);
    const [showStylesMenu, setShowStylesMenu] = useState(false);
    const location = useLocation();
    const megaMenuRef = useRef(null);
    const navigate = useNavigate();

    // Keep track of the previous params
    const previousParams = useRef({
        category_id: null,
        gender_id: null,
        segment_id: null,
        sub_category_id: null,
    });

    // On page load or refresh, get stored category and sub-category from localStorage
    useEffect(() => {
        const storedCategory = localStorage.getItem('activeCategory');
        const storedSubCategory = localStorage.getItem('activeSubCategory');

        // Set the active category and sub-category from localStorage if they exist
        if (storedCategory && !category_id) {
            setActiveCategory(storedCategory);
        }

        if (storedSubCategory && !sub_category_id) {
            setActiveSubCategory(storedSubCategory);
        }

    }, []);

    // Fetch categories
    useEffect(() => {
        const api_token = localStorage.getItem('apiToken');
        const fetchCategories = async () => {
            try {
                const response = await api.get('/website/get_nav_menu', {
                    params: {
                        api_token,
                        gender_id: 2,
                        segment_id: 1,
                        menu_order: 6,
                    },
                });

                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setMenuData(response.data.data || {});
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (api_token != null) {
            fetchCategories();
        }
    }, []);

    // Close menus when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
                setHoveredCategory(null);
                setShowShopMenu(false);
                setShowStylesMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle hover for categories except "Shop" and "Styles"
    const handleMouseEnterCategory = (mainItem) => {
        if (mainItem !== 'Shop' && mainItem !== 'Styles') {
            setHoveredCategory(mainItem);
        }
    };

    const handleMouseLeaveCategory = () => {
        setHoveredCategory(null);
    };

    // Handle hover for "Shop" and "Styles"
    const handleMouseEnterShop = () => {
        setShowStylesMenu(false);
        setShowShopMenu(true);
    };

    const handleMouseEnterStyles = () => {
        setShowShopMenu(false);
        setShowStylesMenu(true);
    };

    const handleMouseLeaveMenus = () => {
        setShowShopMenu(false);
        setShowStylesMenu(false);
    };

    // Handle navigation
    const handleNavigation = (mainItem, subCategoryId, path) => {
        // Prevent navigation if the main item is "Shop" or "Styles"
        if (mainItem === 'Shop' || mainItem === 'Styles') {
            return; // Disable click
        }

        const button = document.getElementById('clearButton');
        if (button) {
            button.click();
        }
        setShowShopMenu(false);
        setShowStylesMenu(false);
        setHoveredCategory(null);

        setActiveCategory(mainItem);
        setActiveSubCategory(subCategoryId);

        // Store selected category and sub-category in localStorage
        localStorage.setItem('activeCategory', mainItem);
        localStorage.setItem('activeSubCategory', subCategoryId);

        navigate(path, { state: { mainItem, subCategoryId } });
    };

    // Update active category and sub-category based on the URL parameters and store them in localStorage
    useEffect(() => {
        if (category_id) {
            setActiveCategory(category_id);
            localStorage.setItem('activeCategory', category_id); // Store in localStorage
        }
        if (sub_category_id) {
            setActiveSubCategory(sub_category_id);
            localStorage.setItem('activeSubCategory', sub_category_id); // Store in localStorage
        }
    }, [category_id, sub_category_id]);

    // Clear the active category and sub-category when navigating to a non-menu route
    useEffect(() => {
        const validRoutes = ['/shop', '/latest-arrivals', '/deals', '/stores','/visit-store'];
        const isValidRoute = validRoutes.some((route) => location.pathname.includes(route));

        if (!isValidRoute) {
            setActiveCategory('');
            setActiveSubCategory('');
        }
    }, [location.pathname]);

    return (
        <ul className="nav-menu">
            {Object.keys(menuData)
                .sort((a, b) => menuData[a].menu_order - menuData[b].menu_order)
                .map((mainItem, index) => {
                    const item = menuData[mainItem];
                    const isCategoryActive = mainItem === activeCategory;
                    const isShopActive = mainItem === 'Shop' && showShopMenu;
                    const isStylesActive = mainItem === 'Styles' && showStylesMenu;

                    return (
                        <li key={`${item.id}-${index}`} className={`menuItemClass ${mainItem} ${isCategoryActive ? 'active' : ''}`}>
                            <a
                                onClick={() => handleNavigation(
                                    mainItem,
                                    '',
                                    item.type === 'category'
                                        ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                                        : item.type === 'deal'
                                        ? '/deals'
                                        : item.type === 'livesocial'
                                        ? '/live-social'
                                        : item.type === 'styles'
                                        ? '#'
                                        : item.type === 'storelisting'
                                        ? '/stores'
                                        : '/'
                                )}
                                onMouseEnter={() => mainItem === 'Shop' ? handleMouseEnterShop() : mainItem === 'Styles' ? handleMouseEnterStyles() : handleMouseEnterCategory(mainItem)}
                                className={mainItem === 'Shop' || mainItem === 'Styles' ? 'disabled-link' : ''}
                            >
                                {mainItem}
                            </a>

                            {/* Sub-categories for categories except "Shop" and "Styles" */}
                            {hoveredCategory === mainItem && item.sub_category_list && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={() => setHoveredCategory(mainItem)}
                                    onMouseLeave={handleMouseLeaveCategory}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.sub_category_list.map((subCategory,index) => (
                                                    <div key={`${subCategory.sub_category_id}-${index}`} className={`col-3 menuItemClassSub-data ${activeSubCategory === subCategory.sub_category_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, subCategory.sub_category_id, `/shop/${subCategory.category_id}/${subCategory.gender_id}/${subCategory.segment_id}/${subCategory.sub_category_id}`)}>
                                                            {subCategory.sub_category_name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Shop menu */}
                            {mainItem === 'Shop' && isShopActive && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={handleMouseEnterShop}
                                    onMouseLeave={handleMouseLeaveMenus}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.categories_list.map((category) => (
                                                    <div key={category.category_id} className={`col-3 menuItemClassSub-data ${activeSubCategory === category.category_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, category.category_id, `/shop/${category.category_id}/${category.gender_id}/${category.segment_id}`)}>
                                                            {category.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Styles menu */}
                            {mainItem === 'Styles' && isStylesActive && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={handleMouseEnterStyles}
                                    onMouseLeave={handleMouseLeaveMenus}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.sidebar.map((sidebars) => (
                                                    <div key={sidebars.tag_id} className={`col-3 menuItemClassSub-data ${activeSubCategory === sidebars.tag_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, sidebars.tag_id, `/latest-arrivals/${sidebars.tag_id}/${sidebars.type}`)}>
                                                            {sidebars.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </li>
                    );
                })}
        </ul>
    );
};

export default NavMenu;
