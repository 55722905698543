import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './LiveSocial.css';
import Modal from '../modal/Modal'; // Import the Modal component

const LiveSocial = ({ videos, loading }) => {
    console.log(videos?.data?.carousel, 'videos.data.carousel'); // Use optional chaining

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedProductLinks, setSelectedProductLinks] = useState([]); // Initialize as empty array

    // Function to open the modal and set the selected video
    const openModal = (videoUrl, productLinks) => {
        console.log(productLinks, 'productLinks');
    
        // Convert product_links to an array if it's not already
        const productLinksArray = Array.isArray(productLinks.product_links)
            ? productLinks.product_links
            : [productLinks.product_links]; // Wrap string in an array
    
        const transformedVideos = productLinksArray.map(link => ({
            ...productLinks, // Spread the original productLinks object to include all properties
            product_sui: link, // Add or override product_sui with the current link
        }));
    
        console.log(transformedVideos, 'transformedVideos');
    
        setSelectedVideo(videoUrl);
        setSelectedProductLinks(transformedVideos[0]); // Set product links when opening the modal
    };
    

    const closeModal = () => {
        setSelectedVideo(null);
        setSelectedProductLinks([]); // Clear product links on close
    };

    return (
        <section className="live">
            <div className="container">
                {loading ? (
                    <>
                        <h2>
                            <Skeleton width={150} height={30} />
                        </h2>
                        <div className="social-carousel">
                            {[...Array(6)].map((_, index) => (
                                <div key={index} className="item">
                                    <div className="img-box">
                                        <Skeleton height={350} width={250} />
                                    </div>
                                    <div className="content">
                                        <Skeleton width={100} />
                                        <Skeleton width={150} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <h2>{videos?.heading || 'Video Gallery'}</h2>
                        <div className="social-carousel">
                            {videos?.data?.carousel?.length > 0 ? (
                                videos.data.carousel.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="img-box">
                                            <img
                                                src={item.video_thumbnail_web}
                                                alt={`Social Image ${index + 1}`}
                                                onClick={() => openModal(item.video_url, item)} // Pass product links
                                            />
                                        </div>
                                        <div className="content">
                                            <h3>
                                                <Link to={`/visit-store/${item.store_uuid}`}>
                                                    {item.store_name}
                                                </Link>
                                            </h3>
                                            <h4>{item.video_description}</h4>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No videos available</p>
                            )}
                        </div>
                    </>
                )}

                {/* Modal for displaying the selected video */}
                <Modal
                    isOpen={!!selectedVideo}
                    onClose={closeModal}
                    videoUrl={selectedVideo}
                    videoData={selectedProductLinks} // Pass product links to the Modal
                />
            </div>
        </section>
    );
};

export default LiveSocial;
