import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import Modal from '../modal/Modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StoreBanner from './StoreBanner';
import StoreVideos from './StoreVideos';
import StoreFilter from './StoreFilter';

const StoreProfile = () => {
    const [storeData, setStoreData] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoDataValue, setVideoDataValue] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
    });

    const { tag_id, tag_type, store_uuid } = useParams();

    // Fetch store data
    const fetchStoreData = useCallback(async () => {
        try {
            setLoading(true);
            const api_token = localStorage.getItem('apiToken');
            const response = await api.get('/store/get_store', {
                params: { api_token, tag_id, tag_type, store_uuid },
            });

            if (response.status === 200) {
                setStoreData(response.data);
                // Initialize filter options
                setFilterOptions({
                    sizeOptions: response.data.size_options || [],
                    priceOptions: response.data.price_options || [],
                    colorOptions: response.data.color_options || [],
                });
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [tag_id, tag_type, store_uuid]);

    useEffect(() => {
        fetchStoreData();
    }, [fetchStoreData]);

    useEffect(() => {
        if (storeData) {
            setBannerUrl(storeData?.store_logo_web || '');
        }
    }, [storeData]);

    const handleProductClick = (videoUrl, videoData) => {
        setVideoUrl(videoUrl);
        setVideoDataValue(videoData);
        setModalOpen(true);
    };

    const handleTabSelection = (selectedTab) => {
        const { sizeOptions, priceOptions, colorOptions } = getFiltersForTab(selectedTab);
        setFilterOptions({ sizeOptions, priceOptions, colorOptions });
    };

    const getFiltersForTab = (tab) => {
        // Placeholder filter options based on tab
        switch (tab) {
            case 'Women':
                return {
                    sizeOptions: ['S', 'M', 'L'],
                    priceOptions: ['<$50', '$50-$100', '>$100'],
                    colorOptions: ['Red', 'Blue', 'Green'],
                };
            case 'Men':
                return {
                    sizeOptions: ['S', 'M', 'L', 'XL'],
                    priceOptions: ['<$50', '$50-$100', '>$100'],
                    colorOptions: ['Black', 'White', 'Gray'],
                };
            case 'Unisex':
                return {
                    sizeOptions: ['S', 'M', 'L', 'XL'],
                    priceOptions: ['<$50', '$50-$100', '>$100'],
                    colorOptions: ['Black', 'White', 'Green'],
                };
            default:
                return {
                    sizeOptions: [],
                    priceOptions: [],
                    colorOptions: [],
                };
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="main-content not-home">
            {loading ? (
                <Skeleton height={300} />
            ) : (
                <StoreBanner
                    bannerUrl={storeData?.store_logo_web}
                    thumburldata={storeData?.store_logo_thumb_web}
                    loading={loading}
                />
            )}

            <StoreVideos
                storeName={storeData?.store_name}
                thumburldata={storeData?.store_logo_thumb_web}
                video_data={storeData?.video_data}
                onProductClick={handleProductClick}
                storeUuid={storeData?.store_user_uuid}
                storeSellers={storeData?.store_sellers}
                follow_status={storeData?.is_following}
                storeId={store_uuid}
                loading={loading}
            />

            <StoreFilter
                priceRange={storeData?.price_range}
                genderList={storeData?.gender_list}
                storeUuid={storeData?.store_uuid}
                loading={loading}
                onTabSelection={handleTabSelection}
            />

            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                videoUrl={videoUrl}
                videoData={videoDataValue}
            />
        </div>
    );
};

export default StoreProfile;
