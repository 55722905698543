import React, { useState, useEffect } from 'react';
import './MultiSelector.css';

const SortSelect = ({ data = [], onChange, selectedValue }) => {
    // Ensure data is not null or undefined and map it to the required format
    const formattedData = data.map((el) => ({
        label: el?.label, // Display label, handle if missing
        value: `${el?.value}` // Use value as the selection
    }));

    const [selected, setSelected] = useState(selectedValue || null); // Use single item for single select
    const [isOpen, setIsOpen] = useState(false); // Manage dropdown visibility

    // Synchronize internal state with the `selectedValue` prop
    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    // Handle item selection
    const handleItemClick = (value) => {
        setSelected(value);
        onChange(value); // Pass the selected value to the parent component
    };

    // Handle clearing the selection
    const clearSelection = () => {
        setSelected(null);
        onChange(null);
    };

    // Get the label of the selected value
    const getSelectedLabel = () => {
        if (selected === 'ASC') return 'Low';
        if (selected === 'DESC') return 'High';
        return '';
    };

    const selectedLabel = getSelectedLabel();

    return (
        <div
            className="filters__item--top js-dropdown dropdown dropdown--full"
            onMouseEnter={() => setIsOpen(true)} // Open dropdown on mouse enter
            onMouseLeave={() => setIsOpen(false)} // Close dropdown on mouse leave
        >
            <button
                className={`js-dropdown__trigger filters__item-title ${isOpen ? 'btn_open' : ''}`}
                aria-haspopup="dialog"
                role="button"
                aria-expanded={isOpen}
            >
                Sort By Price
                <span className="h5 filters__count filters__count-size sort_multi">
                    {selectedLabel ? `(${selectedLabel})` : ''}
                </span>
                <span className="icon icon--arrow-down icon--sm">
                    <svg viewBox="0 0 16 16" aria-hidden="true" focusable="false" role="presentation">
                        <path d="M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"></path>
                    </svg>
                </span>
            </button>

            <div
                className={`filters__dropdown-menu js-dropdown__menu dropdown__menu ${isOpen ? 'open' : ''}`}
                role="dialog"
                style={{
                    visibility: isOpen ? 'visible' : 'hidden',
                    display: isOpen ? 'block' : 'none',
                    opacity: isOpen ? 1 : 0,
                }}
            >
                <div className="filters__listings filters__listings--cap-height">
                    <div className="h5 u-margin-t--none u-margin-l--sm">Price</div>
                    <ul className="ui-list ui-list--inline filters__size-list u-margin-l--sm">
                        {formattedData.map((item) => (
                            <li key={item.value} className={`${selected === item.value ? 'active' : ''}`}>
                                <input
                                    className="js-enterable u-screen-reader"
                                    id={`price-top-Price__${item.value}`}
                                    type="radio" // Use radio for single select
                                    name="price"
                                    value={item.value}
                                    checked={selected === item.value}
                                    onChange={() => handleItemClick(item.value)}
                                />
                                <label
                                    htmlFor={`price-top-Price__${item.value}`}
                                    className="push-button u-margin-b--none"
                                >
                                    {item.label}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="filters__actions">
                    <button className="js-dropdown-close btn btn--sm u-margin-l--sm" onClick={() => setIsOpen(false)}>
                        Done
                    </button>
                    <button className="js-clear-filter" onClick={clearSelection}>
                        <span className="u-margin-l--xl link">Clear</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SortSelect;
