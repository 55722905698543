import React from 'react';

// Function to create the dynamic link for a store
const StoreDynamicLink = async (store_uuid, store_name, store_profile_image) => {
  // URL encode and trim values
  const image = encodeURIComponent(store_profile_image.trim());
  const description = encodeURIComponent(`Profile of ${store_name}`.trim());
  const title = "Modestrend";

  console.log(store_name, store_profile_image);

  // Get necessary data from localStorage
  const currentType = localStorage.getItem('userRole') || 'guest';
  const user_uuid = localStorage.getItem('userUuid');
  const country_code = localStorage.getItem('userCountryCode') || 'US';

  // Firebase Dynamic Links API URL with your Firebase API key
  const API_KEY = "AIzaSyBmAr5tphOF7t3V48143Htw0G535wOP5xM";
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`;

  // Construct the dynamic link with URL encoded parameters
  const dynamicLinkConfig = {
    dynamicLinkInfo: {
      link: `https://connect.seashels.com/api/v1.0/Link/profile?user_id=${encodeURIComponent(store_uuid.trim())}&type=${encodeURIComponent(currentType.trim())}&region=${encodeURIComponent(country_code.trim())}&mediaUrl=${image}&description=${description}`,
      domainUriPrefix: "https://discover.seashels.com",
      androidInfo: {
        androidPackageName: "com.seashels.discover",
        androidFallbackLink: "https://connect.seashels.com",
        androidMinPackageVersionCode: "1",
      },
      iosInfo: {
        iosBundleId: "com.seashels.discover",
        iosFallbackLink: "https://connect.seashels.com",
        iosAppStoreId: "6463077820",
        iosMinimumVersion: "1.0",
      },
      socialMetaTagInfo: {
        socialTitle: title || "Modestrend",
        socialDescription: decodeURIComponent(description) || "Profile of a store on Modestrend.",
        socialImageLink: store_profile_image.trim()  // Use original profile image URL for social sharing
      }
    },
    suffix: {
      option: "SHORT"  // Generates a short dynamic link
    }
  };

  try {
    console.log(`Request URL: ${url}`);
    
    // Making the POST request to Firebase Dynamic Links API
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dynamicLinkConfig),  // Convert config to JSON
    });

    // Check for response status and throw if not OK
    if (!response.ok) {
      const errorResponse = await response.json();  // Parse the error response from Firebase
      console.error("Error response:", errorResponse);
      throw new Error(`Error creating dynamic link: ${errorResponse.error.message}`);
    }

    // Parsing the success response
    const result = await response.json();
    console.log("Generated Dynamic Link:", result.shortLink);

    return result.shortLink;  // Return the short dynamic link
  } catch (error) {
    console.error("Error creating dynamic link:", error);  // Log the error details
  }
};

export default StoreDynamicLink;
