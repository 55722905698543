import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton CSS
import SortSelect from '../multiSelect/SortSelect';
import MultiSelectSize from '../multiSelect/multiSelectSize';
import MultiSelectColor from '../multiSelect/multiSelectColor';
import MultiSelectPrice from '../multiSelect/multiSelectPrice';
import MultiInputPrice from '../multiSelect/MultiInputPrice';
import ClearAllFilterData from '../multiSelect/ClearAllFilterData';

const ShopTabFilter = ({
    sortOptions,
    sizeOptions,
    priceOptions,
    colorOptions,
    onSortChange,
    onSizeChange,
    onPriceChange,
    onColorChange,
    onClearFilters,
    onPriceClearFilter,
    onSizeClearFilter,
    onColorClearFilter,
    sortOption,
    showAdditionalFilters,
    loading, // Accept loading prop
}) => {
    // State to manage selected options
    const [selectedSort, setSelectedSort] = useState(sortOption || '');
    const [selectedSize, setSelectedSize] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedPriceRange, setSelectedPriceRange] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Initially loading is true

    // Effect to handle the initial loading
    useEffect(() => {
        // Set isLoading based only on the `loading` prop, ignore filter changes
        setIsLoading(loading);

        // Clear loading state once the loading prop becomes false (data is fetched)
        if (!loading) {
            setIsLoading(false);
        }
    }, [loading]); // Only track changes to `loading` prop, not filters

    return (
        <div className="tab-filter">
            <div className="filter-option">
                {/* <span>Sort By</span> */}

                {isLoading ? ( 
                    <div>
                        <Skeleton width={155} />
                    </div>
                ) : (
                    <SortSelect
                        onChange={(value) => {
                            setSelectedSort(value);
                            onSortChange(value);
                        }}
                        data={sortOptions}
                        selectedValue={selectedSort}
                    />
                )}

                {showAdditionalFilters && (
                    <>
                        {isLoading ? (
                            <div>
                                <Skeleton width={155} />
                            </div>
                        ) : (
                            <MultiInputPrice 
                                selectedPriceValue={selectedPriceRange} 
                                onChange={(value) => {
                                    setSelectedPriceRange(value);
                                    onPriceChange(value);
                                }} 
                                onPriceClearFilter={onPriceClearFilter} 
                            />
                        )}

                        {isLoading ? ( 
                            <div>
                                <Skeleton width={155} />
                            </div>
                        ) : (
                            <MultiSelectSize
                                onChange={(value) => {
                                    setSelectedSize(value);
                                    onSizeChange(value);
                                }}
                                data={sizeOptions}
                                selectedSizes={selectedSize}
                                onSizeClearFilter={onSizeClearFilter}
                            />
                        )}

                        {isLoading ? ( 
                            <div>
                                <Skeleton width={155} />
                            </div>
                        ) : (
                            <MultiSelectColor
                                onChange={(value) => {
                                    setSelectedColors(value);
                                    onColorChange(value);
                                }}
                                data={colorOptions}
                                selectedColors={selectedColors}
                                onColorClearFilter={onColorClearFilter}
                            />
                        )}
                    </>
                )}
            </div>

            <div className="clear">
                <ClearAllFilterData
                    onClearFilters={onClearFilters}
                    setSelectedColors={setSelectedColors}
                    setSelectedSizes={setSelectedSize}
                    setSelectedPrice={setSelectedPrice}
                    setSelectedPriceRange={setSelectedPriceRange}
                    setSelectedSort={setSelectedSort}
                />
            </div>
        </div>
    );
};

ShopTabFilter.propTypes = {
    sortOptions: PropTypes.array.isRequired,
    sizeOptions: PropTypes.array.isRequired,
    priceOptions: PropTypes.array.isRequired,
    colorOptions: PropTypes.array.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onPriceChange: PropTypes.func.isRequired,
    onColorChange: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    onPriceClearFilter: PropTypes.func.isRequired,
    onSizeClearFilter: PropTypes.func.isRequired,
    onColorClearFilter: PropTypes.func.isRequired,
    sortOption: PropTypes.string.isRequired,
    showAdditionalFilters: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired, // Added loading prop type
};

export default ShopTabFilter;
