import React, { useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './ProductDetailsImageBlock.css';

const ProductDetailsImageBlock = ({ imageData, storeUuid, videoData, isWishlist, isFavorite, sui, color_id, size_id, systemName }) => {
    const [mainImage, setMainImage] = useState('');
    const [isZooming, setIsZooming] = useState(false);
    const [currentZoom, setCurrentZoom] = useState(1);
    const transformWrapperRef = useRef(null);
    const imageCacheRef = useRef({}); // In-memory cache for preloaded images

    const maxZoomLevel = 5;
    const zoomStep = 1;

    // Preload all large images and always set the first thumbnail as the main image
    useEffect(() => {
        if (imageData.length > 0) {
            imageData.forEach((img) => {
                const imgLarge = new Image();
                imgLarge.src = img.image_url;
                imageCacheRef.current[img.image_url] = imgLarge;
            });

            // Always select the first image when refreshing or visiting the page
            setMainImage(imageData[0].image_url);
            localStorage.setItem('mainImage', imageData[0].image_url); // Cache first image as the main image
        }
    }, [imageData]);

    // Update thumbnail and reset zoom
    const updateActiveThumbnail = (thumbnail) => {
        const largeImage = thumbnail.dataset.large;
        setMainImage(largeImage);
        localStorage.setItem('mainImage', largeImage); // Cache selected image in localStorage
        resetZoom();
    };

    const handleThumbnailClick = (url, e) => {
        setMainImage(url);
        localStorage.setItem('mainImage', url); // Persist to localStorage
        updateActiveThumbnail(e.currentTarget);
    };

    const handleNextThumbnail = () => {
        const thumbnails = document.querySelectorAll('.thumbnail');
        const currentIndex = Array.from(thumbnails).findIndex(
            (thumbnail) => thumbnail.dataset.large === mainImage
        );
        const nextIndex = (currentIndex + 1) % thumbnails.length;
        const nextThumbnail = thumbnails[nextIndex];
        updateActiveThumbnail(nextThumbnail);
    };

    const handlePrevThumbnail = () => {
        const thumbnails = document.querySelectorAll('.thumbnail');
        const currentIndex = Array.from(thumbnails).findIndex(
            (thumbnail) => thumbnail.dataset.large === mainImage
        );
        const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length;
        const prevThumbnail = thumbnails[prevIndex];
        updateActiveThumbnail(prevThumbnail);
    };

    const handleZoomToggle = (zoomIn, zoomOut, resetTransform) => {
        if (currentZoom >= maxZoomLevel) {
            if (currentZoom > 1) {
                zoomOut();
                setCurrentZoom((prevZoom) => Math.max(prevZoom - zoomStep, 1));
            } else {
                resetTransform();
                setIsZooming(false);
            }
        } else {
            zoomIn();
            setCurrentZoom((prevZoom) => Math.min(prevZoom + zoomStep, maxZoomLevel));
            setIsZooming(true);
        }
    };

    const resetZoom = () => {
        if (transformWrapperRef.current) {
            transformWrapperRef.current.resetTransform();
            setCurrentZoom(1);
            setIsZooming(false);
        }
    };

    return (
        <div className="image-block">
            <div className="product-gallery">
                <div className="thumbnails">
                    {imageData.map((img, index) => (
                        <div
                            key={index}
                            className={`thumbnail ${mainImage === img.image_url ? 'active' : ''}`}
                            onClick={(e) => handleThumbnailClick(img.image_url, e)}
                            data-large={img.image_url}
                        >
                            <img src={img.image_url_thumb} alt={`Thumbnail ${index + 1}`} />
                        </div>
                    ))}
                </div>
                <div className="large-image" onMouseLeave={resetZoom}>
                    <TransformWrapper
                        ref={transformWrapperRef}
                        initialScale={1}
                        minScale={1}
                        maxScale={maxZoomLevel}
                        centerOnInit={true}
                        wheel={{ step: 0.1 }}
                        doubleClick={{ step: 1 }}
                        panning={{ disabled: isZooming }}
                        pinch={{ disabled: false }}
                        zoomAnimation={{ disabled: false, size: 0.5 }}
                        onZoomChange={(ref) => {
                            setCurrentZoom(ref.state.scale);
                            setIsZooming(ref.state.scale > 1);
                        }}
                    >
                        {({ zoomIn, zoomOut, resetTransform }) => (
                            <TransformComponent>
                                <img
                                    src={mainImage}
                                    alt="Large View"
                                    onClick={() => handleZoomToggle(zoomIn, zoomOut, resetTransform)}
                                    style={{ cursor: isZooming ? 'default' : 'zoom-in' }}
                                />
                            </TransformComponent>
                        )}
                    </TransformWrapper>
                    <button className="prev-arrow" onClick={handlePrevThumbnail}>
                        <img src="/images/back-arrow.png" alt="Previous" />
                    </button>
                    <button className="next-arrow" onClick={handleNextThumbnail}>
                        <img src="/images/next-arrow.png" alt="Next" />
                    </button>
                </div>
            </div>
            {/* <ProductIcons
                isWishlist={isWishlist}
                isFavorite={isFavorite}
                videoData={videoData}
                storeUuid={storeUuid}
                itemId={sui}
                color_id={color_id}
                size_id={size_id}
                systemName={systemName}
            /> */}
        </div>
    );
};

export default ProductDetailsImageBlock;
