const shareVideosLink = async (video_id, store_uuid, imagedata) => {
    const image = imagedata.image_url || "https://fallbackimageurl.com/default.jpg";  // Ensure valid image URL
    const currentType = localStorage.getItem('userRole') || 'guest';
    const user_uuid = localStorage.getItem('userUuid');
    const country_code = localStorage.getItem('userCountryCode') || 'US';
    const API_KEY = "AIzaSyBmAr5tphOF7t3V48143Htw0G535wOP5xM";
    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`;
    const description = '';
    const title = '';
    // Constructing dynamic link configuration object
    const dynamicLinkConfig = {
      dynamicLinkInfo: {
        link: `https://connect.seashels.com/api/v1.0/Link/brodcast?id=${video_id}&store_id=${store_uuid}&brodcasttype=video&sender_id=${user_uuid}&region=${country_code}`, // Use URL encoded description
        domainUriPrefix: "https://discover.seashels.com",
        androidInfo: {
          androidPackageName: "com.seashels.discover",
          androidFallbackLink: "https://connect.seashels.com",
          androidMinPackageVersionCode: "1"
        },
        iosInfo: {
          iosBundleId: "com.seashels.discover",
          iosFallbackLink: "https://connect.seashels.com",
          iosAppStoreId: "6463077820",
          iosMinimumVersion: "1.0"
        },
        socialMetaTagInfo: {
          socialTitle: title || "Modestrend",
          socialDescription: description || "Modesty with Style",
          socialImageLink: image
        }
      },
      suffix: {
        option: "SHORT"
      }
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dynamicLinkConfig)
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error response:", errorResponse);
        throw new Error(`Error creating dynamic link: ${errorResponse.error.message}`);
      }
  
      const result = await response.json();
      console.log("Generated Dynamic Link:", result.shortLink);
      return result.shortLink;
    } catch (error) {
      console.error("Error creating dynamic link:", error);
    }
  };
  
  
  export default shareVideosLink;
  