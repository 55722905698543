// src/StripeContainer.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import PaymentForm from './PaymentForm';
import PaymentModal from './PaymentModal';

// Load your publishable key from Stripe
const stripePromise = loadStripe("pk_test_51NeDfvKtGDMY14eQ9VNQuKvaF0rwmxoSUsgNhMW3GScNm1rwtC0gh7dKOzs0R0GErzys165V67wP2F4FZNa7537b00ryjT4olG");

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentModal />
    </Elements>
  );
};

export default StripeContainer;
