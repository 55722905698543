import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { useAuth } from '../../context/AuthContext'; 
import ShopTabFilter from './ShopTabFilter';
import SubCategoryList from './SubCategoryList';
import ShopFilterProductListing from './ShopFilterProductListing';

const ShopFilter = ({
    loading,
    productdetails,
    SubCategoryList_data,
    storeUuid,
    isLoading,
}) => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();
    const previousParams = useRef({
        category_id: null,
        gender_id: null,
        segment_id: null,
        sub_category_id: null,
    });

    const [tab, setTab] = useState('all');
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(true);
    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
        sortOptions: [
            { value: 'ASC', label: 'Price Low To High' },
            { value: 'DESC', label: 'Price High To Low' },
        ],
    });
    const [currencyCode, setCurrencyCode] = useState(localStorage.getItem('currencyCode') || '');
    const [sortOption, setSortOption] = useState('');
    const [sizeOption, setSizeOption] = useState([]);
    const [priceOption, setPriceOption] = useState('');
    const [colorOption, setColorOption] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [productData, setProductData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true); // Track the first load

    useEffect(() => {
        const areParamsSame =
            previousParams.current.category_id === category_id &&
            previousParams.current.gender_id === gender_id &&
            previousParams.current.segment_id === segment_id &&
            previousParams.current.sub_category_id === sub_category_id;

        const fetchData = async () => {
            // Show loading only on the initial page load, not on filter changes
            if (initialLoad) setIsLoadingData(true);

            try {
                const api_token = localStorage.getItem('apiToken');
                if (!api_token) throw new Error('API token is missing');

                const formattedSize = sizeOption.length > 0 ? sizeOption.join(',') : '';
                const formattedColor = colorOption.length > 0 ? colorOption.join(',') : '';

                const response = await api.get('/filters/get_skus_for_filter', {
                    params: {
                        api_token,
                        page: 0,
                        store_uuid: storeUuid,
                        segment_id,
                        gender_id,
                        category_list: category_id,
                        currency_code: currencyCode,
                        sort_order: sortOption,
                        size_list: formattedSize,
                        color_list: formattedColor,
                        price_min: priceOption.split('-')[0] || '',
                        price_max: priceOption.split('-')[1] || '',
                        sub_category_list: sub_category_id || selectedSubCategory || '',
                    },
                });

                if (response.status === 401) {
                    logout();
                    navigate('/home');
                    return;
                }

                if (response.status === 200) {
                    const responseData = response.data;
                    if (!responseData) throw new Error('Response data is missing');
                    setProductData(responseData);

                    const colorListResponse = await api.get('/filters/get_colors_list', {
                        params: {
                            api_token,
                            gender_id,
                            segment_id,
                            categories_list: category_id,
                            currency_code: currencyCode,
                            store_uuid: storeUuid,
                            sub_categories_list: sub_category_id || selectedSubCategory || '',
                            price_min: priceOption.split('-')[0] || '',
                            price_max: priceOption.split('-')[1] || '',
                        },
                    });

                    const sizeListResponse = await api.get('/filters/get_sizes_list', {
                        params: {
                            api_token,
                            gender_id,
                            segment_id,
                            categories_list: category_id,
                            store_uuid: storeUuid,
                            currency_code: currencyCode,
                            sub_categories_list: sub_category_id || selectedSubCategory || '',
                            price_min: priceOption.split('-')[0] || '',
                            price_max: priceOption.split('-')[1] || '',
                        },
                    });

                    const colorListData = colorListResponse.data?.colors_data || [];
                    const sizeListData = sizeListResponse.data?.sizes_data || [];
                    const priceListData = responseData.price_band || [];

                    setFilterOptions(prevOptions => ({
                        ...prevOptions,
                        sizeOptions: sizeListData,
                        priceOptions: priceListData,
                        colorOptions: colorListData,
                    }));

                    setShowAdditionalFilters(true);
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoadingData(false);
                setInitialLoad(false); // Reset after the initial load
            }
        };

        fetchData();
        previousParams.current = { category_id, gender_id, segment_id, sub_category_id };
    }, [category_id, gender_id, segment_id, sub_category_id, storeUuid, currencyCode, sortOption, sizeOption, priceOption, colorOption, logout, navigate, initialLoad]);

    const handleSortChange = useCallback((e) => {
        setSortOption(e);
    }, []);

    const handleSizeChange = useCallback((e) => {
        setSizeOption(e);
    }, []);

    const handlePriceChange = useCallback((e) => {
        setPriceOption(e);
    }, []);

    const handleColorChange = useCallback((e) => {
        setColorOption(e);
    }, []);

    const handleClearFilters = useCallback(() => {
        setPriceOption('');
        setSortOption('');
        setSizeOption([]);
        setColorOption([]);
        setSelectedSubCategory(null);
        setProductData(null);
        navigate(`/shop/${category_id}/${gender_id}/${segment_id}`);
    }, [category_id, gender_id, segment_id, navigate]);

    const handleSubCategoryChange = useCallback((subCategoryId) => {
        setPriceOption('');
        setSortOption('');
        setSizeOption([]);
        setColorOption([]);
        setSelectedSubCategory(subCategoryId);
        navigate(`/shop/${category_id}/${gender_id}/${segment_id}/${subCategoryId}`);
    }, [category_id, gender_id, segment_id, navigate]);

    const handlePriceClearFilter = useCallback(() => {
        setPriceOption('');
        setShowAdditionalFilters(true);
    }, []);

    const handleSizeClearFilter = useCallback(() => {
        setSizeOption([]);
        setShowAdditionalFilters(true);
    }, []);

    const handleColorClearFilter = useCallback(() => {
        setColorOption([]);
        setShowAdditionalFilters(true);
    }, []);

    return (
        <div className="store-filter">
            <div className="container">
                <div className="parent-shop-container">
                    <div className="left-sidebar">
                        <SubCategoryList
                            categoryData={SubCategoryList_data}
                            onSubCategoryChange={handleSubCategoryChange}
                            onClearFilters={handleClearFilters}
                            subCategoryStatus={sub_category_id}
                            isLoading={isLoading}
                        />
                    </div>
                    <div className="store-filter_right">
                        <ShopTabFilter
                            sortOptions={filterOptions.sortOptions}
                            sizeOptions={filterOptions.sizeOptions}
                            priceOptions={filterOptions.priceOptions}
                            colorOptions={filterOptions.colorOptions}
                            onSortChange={handleSortChange}
                            onSizeChange={handleSizeChange}
                            onPriceChange={handlePriceChange}
                            onColorChange={handleColorChange}
                            onClearFilters={handleClearFilters}
                            onPriceClearFilter={handlePriceClearFilter}
                            onSizeClearFilter={handleSizeClearFilter}
                            onColorClearFilter={handleColorClearFilter}
                            sortOption={sortOption}
                            sizeOption={sizeOption}
                            priceOption={priceOption}
                            colorOption={colorOption}
                            showAdditionalFilters={showAdditionalFilters}
                            loading={initialLoad ? isLoadingData : false} // Only loading on initial load
                        />
                        {productData && (
                            <ShopFilterProductListing
                                storeUuid={storeUuid}
                                sortOption={sortOption}
                                sizeOption={sizeOption}
                                priceOption={priceOption}
                                colorOption={colorOption}
                                segmentId={segment_id}
                                genderId={gender_id}
                                categoryList={category_id}
                                sub_category_list={sub_category_id}
                                productData={productData}
                                onClearFilters={handleClearFilters}
                                loading={isLoadingData}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopFilter;
