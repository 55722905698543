import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './LatestArrivals.css';
import LazyLoadImage from '../LazyLoadImage'; // Import the LazyLoadImage component

const LatestArrivals = ({ products = {}, loading }) => { // Default to empty object
    const navigate = useNavigate();

    // Owl Carousel settings
    const settings = {
        items: 5,
        margin: 10,
        loop: true,
        autoplay: false,
        autoplayTimeout: 4000,
        dots: true,
        responsive: {
            0: { items: 2 },
            768: { items: 3 },
            1024: { items: 4 },
            1200: { items: 5 },
        },
    };

    const handleProductClick = (sui) => {
        navigate(`/product/${sui}`);
    };

    // Default products if data is not loaded
    const carouselItems = products.data?.carousel || []; // Use optional chaining
    const heading = products.heading || 'Latest Arrivals';

    return (
        <section className="latest">
            <div className="container">
                <h2>
                    {loading ? <Skeleton width={200} /> : heading}
                    {!loading && carouselItems.length > 0 && (
                        <Link
                            to={`/latest-arrivals/${carouselItems[0]?.tag_id || ''}/${carouselItems[0]?.tag_type || ''}`}
                            className="read-more"
                        >
                            {/* Read More Link Text or Icon */}
                        </Link>
                    )}
                </h2>
                {loading ? (
                    <div className="carousel-skeleton social-carousel">
                        {[...Array(6)].map((_, index) => (
                            <div key={index} className="item">
                                <div className="img-box">
                                    <Skeleton height={350} width={230} />
                                </div>
                                <div className="content">
                                    <Skeleton width={100} height={30} style={{ marginTop: '10px' }} />
                                    <Skeleton width={150} height={40} />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='social-carousel'>
                        {carouselItems.length > 0 ? (
                            carouselItems.map((product, index) => (
                                <div key={index} className="item" onClick={() => handleProductClick(product.sui)}>
                                    <div className='img-box'>
                                        <Link to={`/product/${product.sui}`}>
                                            <LazyLoadImage
                                                thumbnailSrc={product.image_url_thumb || 'https://via.placeholder.com/300'}
                                                fullImageSrc={product.image_url || 'https://via.placeholder.com/300'}
                                                alt={product.product_title}
                                                onError={(e) => (e.target.src = 'https://via.placeholder.com/300')}
                                            />
                                            {(product.in_stock === 0 || product.in_stock === '0') && (
                                                <span className="sold-out">Sold Out</span>
                                            )}
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <h4>
                                            <Link to={`/visit-store/${product.store_uuid}`}>{product.store_name}</Link>
                                        </h4>
                                        <div className="price">
                                            <span>
                                                <Link to={`/product/${product.sui}`}>{product.product_title}</Link>
                                            </span>
                                            <div className='price-content-sec'>
                                                {product.display_total_price !== product.display_final_price && (
                                                    <span className="strike_price">
                                                        {`${product.display_currency_symbol}${product.display_total_price}`}
                                                    </span>
                                                )}
                                                <span className="final_price">
                                                    {`${product.display_currency_symbol}${product.display_final_price}`}
                                                </span>
                                                {product.display_total_price !== product.display_final_price && (
                                                    <span className="discount">
                                                        {`${product.display_discount}% off`}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No products available.</p>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default LatestArrivals;
