//src/component/userlogin.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAuth } from '../../context/AuthContext';
import api from '../../api'; // Adjust the path based on your project structure
import { useLogin } from '../../hooks/useLogin';
import { convertPhoneNumber, CountryCodeWithString } from '../../utils/phoneUtils';

const extractUuid = (guestToken) => {
    return guestToken.split('_')[1];
};
const UserLogin = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('userUid') && localStorage.getItem('LoggedIn') && localStorage.getItem('userRole') === 'shopper')
            navigate('/');
    });
    const { login } = useAuth(); // Access login function from useAuth
    const [activeTab, setActiveTab] = useState('login');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showConfirmPasswordField, setShowConfirmPasswordField] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(true);
    const [otpVerified, setOtpVerified] = useState(false);

    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
        setPhone('');
        setEmail('');
        setPassword('');
        setOtp('');
        setNewPassword('');
        setConfirmPassword('');
        setShowOtpField(false);
        setShowPasswordField(false);
        setShowConfirmPasswordField(false);
        setError(null);
        setOtpVerified(false);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        // setLoading(true);

        if (otp) {
            try {
                const device_token = localStorage.getItem('apiToken');
                const uuid = extractUuid(device_token);
                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';
                const clientId = localStorage.getItem('clientId')
                const otpResponse = await api.post('/user/login', {
                    otp,
                    username,
                    country_code: country_code,
                    device_token: clientId,
                    device_type: 'web',
                    app_version: '1.0',
                    guest_api_token: device_token,
                    // user_uuid: user_uid,
                    password: ''
                });




                if (otpResponse.data && otpResponse.data.api_token && otpResponse.data.status === 200 && otpResponse.data.message === 'success') {
                    localStorage.setItem('apiToken', otpResponse.data.api_token);
                    localStorage.setItem('userRole', otpResponse.data.role);
                    localStorage.setItem('userName', otpResponse.data.name);
                    localStorage.setItem('userEmail', otpResponse.data.email);
                    localStorage.setItem('userUid', otpResponse.data.user_uid);
                    localStorage.setItem('LoggedIn', true);
                    login(otpResponse.data.api_token, otpResponse.data.role);
                    navigate('/');
                } else {
                    setError('OTP verification failed.');
                }
            } catch (err) {
                console.error('OTP confirmation error:', err);
                setError('Failed to confirm OTP.');
            } finally {
                setLoading(false);
            }
        } else if (password) {
            try {
                const device_token = localStorage.getItem('apiToken');

                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';

                if (!username) {
                    console.error('Error: Both email and phone are empty.');
                    setError('Error: Both email and phone are empty.');
                    return;
                } else {

                    // Check if the user exists
                    const checkUserResponse = await api.post('/user/check_user', {
                        username: username,
                    });
                    const clientId = localStorage.getItem('clientId');
                    if (checkUserResponse.data.status === 200) {
                        const response = await api.post('/user/login', {
                            username,
                            password,
                            app_version: '1.0',
                            device_type: 'web',
                            device_token: clientId,
                            country_code,
                        });


                        if (response.data && response.data.api_token) {
                            localStorage.setItem('apiToken', response.data.api_token);
                            localStorage.setItem('userRole', response.data.role);
                            localStorage.setItem('userName', response.data.name);
                            localStorage.setItem('userUid', response.data.user_uid);
                            localStorage.setItem('userEmail', response.data.email);
                            localStorage.setItem('LoggedIn', true);
                            login(response.data.api_token, response.data.role);

                            navigate('/');
                        }

                        else {
                            setError(response.data.message);
                        }


                    } else if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                        // User does not exist, show an error message
                        setError("Your account doesn't exist with this phone or email. Please sign up first, then try again.");
                    } else {
                        throw new Error('Unexpected response from server.');
                    }
                }

            } catch (err) {
                console.error('Login error:', err);
                setError('Login failed.');
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setError('Please enter either an OTP or a password.');
        }
    };

    const handleGetOtp = async (e) => {
        e.preventDefault();
        // setLoading(true);

        try {
            let response;
            const username = email ? email : '' || (phone ? convertPhoneNumber(phone) : '');

            if (!username) {
                console.error('Error: Both email and phone are empty.');
                setError('Error: Both email and phone are empty.');
                return;
            }
            // Check if the user exists
            const checkUserResponse = await api.post('/user/check_user', {
                username: username,
            });


            if (activeTab === 'login') {
                // Handle login flow
                if (checkUserResponse.data.status === 200) {
                    // User exists, proceed with OTP login
                    response = await api.post('/user/otp_login', {
                        email: email || '',
                        phone: phone ? convertPhoneNumber(phone) : '',
                    });
                    setError(null);
                    setShowOtpField(true);
                } else if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                    // User does not exist, show an error message
                    setError("Your account doesn't exist with this phone or email. Please sign up first, then try again.");
                    // setShowOtpField(false); // Hide OTP field if shown
                } else {
                    throw new Error('Unexpected response from server.');
                }
            } else if (activeTab === 'signup') {
                // Handle signup flow
                if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                    // User does not exist, proceed with signup

                    const device_token = localStorage.getItem('apiToken');

                    let device_token_new;
                    if (device_token && !device_token.startsWith('guest_')) {
                        device_token_new = `guest_${device_token}`;
                        localStorage.setItem('apiToken', device_token_new);
                    }
                    response = await api.post('/user/signup', {
                        email: email || '',
                        phone: phone ? convertPhoneNumber(phone) : '',
                        country_code: phone ? CountryCodeWithString(phone) : 'US',
                        guest_token: device_token_new,
                        password: '',
                        referral_code: '',
                        gender: '',
                        role: 'shopper',
                        name: '',
                    });
                    setShowOtpField(true);
                    setError(null);

                } else if (checkUserResponse.data.status === 200) {
                    // User already exists, show an error message
                    setError("Your account already exists with this phone or email. Please sign in.");
                    // setShowOtpField(false); // Hide OTP field if shown
                } else {
                    throw new Error('Unexpected response from server.');
                }
            }
        } catch (err) {
            console.error('OTP request error:', err);
            setError('Failed to get OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleSignup = async (e) => {
        e.preventDefault();
        // setLoading(true);

        try {
            // Retrieve device token from localStorage
            let device_token = localStorage.getItem('apiToken');

            // If device token doesn't start with 'guest_', add the prefix
            if (device_token && !device_token.startsWith('guest_')) {
                device_token = `guest_${device_token}`;
                localStorage.setItem('apiToken', device_token);
            }

            // OTP-based signup or login
            if (!showPasswordField) {

                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';
                const clientId = localStorage.getItem('clientId')
                // OTP Login API call
                const otpResponse = await api.post('/user/login', {
                    otp,
                    username,
                    app_version: '1.0',
                    country_code,
                    device_token: clientId,
                    device_type: 'web',
                    guest_api_token: device_token,
                    password: '',
                });


                // Handle successful OTP login
                if (otpResponse.data?.status === 200 && otpResponse.data?.message === 'success') {
                    handleSuccessfulLogin(otpResponse.data);
                } else {
                    setShowOtpField(true);
                    setError('OTP verification failed.');
                }

            }
            // Handle OTP confirmation and password setup
            else if (newPassword && confirmPassword && otp) {
                const user_uid = localStorage.getItem('user_uuid');

                // Confirm OTP API call
                const otpConfirmResponse = await api.post('/user/confirm_otp', {
                    otp,
                    email: email || '',
                    phone: phone ? convertPhoneNumber(phone) : '',
                    user_uuid: user_uid,
                });


                // If OTP verification is successful, handle password-based login
                if (otpConfirmResponse.data?.status === 200 && otpConfirmResponse.data?.message === 'OTP verified') {
                    await handlePasswordLogin(newPassword, device_token);
                } else {
                    setError('OTP verification failed.');
                }

            }
            // Handle direct password-based signup
            else if (newPassword && confirmPassword) {
                if (newPassword === confirmPassword) {
                    await handlePasswordSignup(device_token);
                } else {
                    setError('Passwords do not match.');
                }
            }
        } catch (err) {
            console.error('Sign-up error:', err);
            setError('Sign-up failed.');
        } finally {
            setLoading(false);
        }
    };

    // Helper function to handle successful OTP login
    const handleSuccessfulLogin = (data) => {
        localStorage.setItem('apiToken', data.api_token);
        localStorage.setItem('userRole', data.role);
        localStorage.setItem('userName', data.name);
        localStorage.setItem('userEmail', data.email);
        localStorage.setItem('userUid', data.user_uid);
        localStorage.setItem('LoggedIn', true);
        login(data.api_token, data.role);

        setError(null);
        navigate('/');
    };

    // Helper function to handle password login after OTP confirmation
    const handlePasswordLogin = async (password, device_token) => {
        const username = email || (phone ? convertPhoneNumber(phone) : '');
        const country_code = phone ? CountryCodeWithString(phone) : 'US';
        const clientId = localStorage.getItem('clientId')
        const loginResponse = await api.post('/user/login', {
            otp: '',
            username,
            country_code,
            device_token: clientId,
            device_type: 'web',
            app_version: '1.0',
            guest_api_token: device_token,
            password,
        });


        if (loginResponse.data?.status === 200 && loginResponse.data?.message === 'success') {
            handleSuccessfulLogin(loginResponse.data);
        } else {
            setError('OTP verification failed.');
        }
    };

    // Helper function to handle password-based signup
    const handlePasswordSignup = async (device_token) => {
        const signupResponse = await api.post('/user/signup', {
            email: email || '',
            phone: phone ? convertPhoneNumber(phone) : '',
            country_code: phone ? CountryCodeWithString(phone) : 'US',
            guest_token: device_token,
            password: newPassword,
            referral_code: '',
            gender: '',
            role: 'shopper',
            name: '',
        });


        if (signupResponse.data?.status === 400) {
            setError(signupResponse.data.message);
        } else {
            setShowOtpField(true);
            localStorage.setItem('user_uuid', signupResponse.data.user_uuid);
        }
    };

    const toggleOtpField = () => {
        setShowOtpField(true);
        setShowPasswordField(false);
        setShowConfirmPasswordField(false);
    };

    const togglePasswordField = () => {
        setShowOtpField(false);
        setShowPasswordField(true);
        setShowConfirmPasswordField(true);
        setOtpVerified(true);
    };

    const handlePhoneChange = (value) => {
        const phoneNumber = value.replace(/^\+/, '');
        const countryCode = phoneNumber.slice(0, 2);
        const numberWithoutCountryCode = phoneNumber.slice(2);
        const formattedPhone = `${countryCode}-${numberWithoutCountryCode}`;

        setPhone(formattedPhone);
        setEmail('');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setPhone('');
    };

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    return (
        <div id="wrapper">
            {/* <div className="logo account-sign">
                <a href="/">
                    <img src="/images/logo.png" alt="Logo" />
                </a>
            </div> */}
            <div className="signin-block">
                <div className="container">
                    <div className="tabs">
                        <ul id="tabs-nav">
                            <li className={activeTab === 'login' ? 'active' : ''}>
                                <button onClick={() => handleTabSwitch('login')}>
                                    Sign in
                                </button>
                            </li>
                            <li className={activeTab === 'signup' ? 'active' : ''}>
                                <button onClick={() => handleTabSwitch('signup')}>
                                    Sign Up
                                </button>
                            </li>
                        </ul>
                        <div id="tabs-content">
                            {activeTab === 'login' && (
                                <div id="tab1" className="tab-content">
                                    <form onSubmit={handleLogin} autoComplete="off">
                                        <div className="input-row">
                                            <PhoneInput
                                                country={'us'}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                inputStyle={{ width: '100%' }}
                                                disabledCountryCode
                                                inputProps={{
                                                    name: `phone_${Math.random()}`,
                                                    id: `phone_${Math.random()}`,
                                                    autoComplete: 'off',
                                                }}
                                            />
                                        </div>
                                        <span>or</span>
                                        <div className="input-row">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                name={`email_${Math.random()}`}
                                                id={`email_${Math.random()}`}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="btn-row">
                                            <ul>
                                                <li>
                                                    <button
                                                        type="button"
                                                        id="otp"
                                                        onClick={handleGetOtp}
                                                    >
                                                        Get OTP
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordField}
                                                    >
                                                        Enter Password
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        {showOtpField && (
                                            <div className="input-row otp">
                                                <input
                                                    type="number"
                                                    placeholder="Enter OTP Here"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    name={`otp_${Math.random()}`}
                                                    id={`otp_${Math.random()}`}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        )}
                                        {showPasswordField && (
                                            <>
                                                <div className="input-row">
                                                    <input
                                                        type="password"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        name={`password_${Math.random()}`}
                                                        id={`password_${Math.random()}`}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <a href="/reset-password" className="forgot-pass">
                                            Forgot password?
                                        </a>
                                        <div className="input-submit">
                                            <input
                                                type="submit"
                                                value={loading ? 'Logging in...' : 'Sign in'}
                                                disabled={loading}
                                            />
                                        </div>
                                        {error && <div className="error-message">{error}</div>}
                                    </form>
                                </div>
                            )}
                            {activeTab === 'signup' && (
                                <div id="tab2" className="tab-content">
                                    <form onSubmit={handleSignup} autoComplete="off">
                                        <div className="input-row">
                                            <PhoneInput
                                                country={'us'}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                inputStyle={{ width: '100%' }}
                                                disabledCountryCode
                                                inputProps={{
                                                    name: `phone_${Math.random()}`,
                                                    id: `phone_${Math.random()}`,
                                                    autoComplete: 'off',
                                                }}
                                            />
                                        </div>
                                        <span>or</span>
                                        <div className="input-row">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                name={`email_${Math.random()}`}
                                                id={`email_${Math.random()}`}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="btn-row">
                                            <ul>
                                                <li>
                                                    <button
                                                        type="button"
                                                        id="otp"
                                                        onClick={handleGetOtp}
                                                    >
                                                        Get OTP
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordField}
                                                    >
                                                        Enter Password
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        {showOtpField && (
                                            <div className="input-row otp">
                                                <input
                                                    type="number"
                                                    placeholder="123456"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    name={`otp_${Math.random()}`}
                                                    id={`otp_${Math.random()}`}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        )}
                                        {showPasswordField && (
                                            <>
                                                <div className="input-row">
                                                    <input
                                                        type="password"
                                                        placeholder="New Password"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        name={`newPassword_${Math.random()}`}
                                                        id={`newPassword_${Math.random()}`}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="input-row confirm-pass">
                                                    <input
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        name={`confirmPassword_${Math.random()}`}
                                                        id={`confirmPassword_${Math.random()}`}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="agree-text">
                                            <input
                                                type="checkbox"
                                                checked={agreeTerms}
                                                onChange={handleCheckboxChange}
                                                name={`agree_${Math.random()}`}
                                                id={`agree_${Math.random()}`}
                                            />
                                            <label htmlFor={`agree_${Math.random()}`}>
                                                By clicking Create account, I agree that I have
                                                read and accepted the{' '}
                                                <a href="#">Terms of use</a> and{' '}
                                                <a href="#">Privacy Policy</a>
                                            </label>
                                        </div>
                                        <div className="signin-block">
                                            <div className="container">
                                                {/* Other components and code */}
                                                {error && <div className="error-message">{error}</div>} {/* Render error message */}
                                                {/* Form for signup and login */}
                                            </div>
                                        </div>
                                        <div className="input-submit">
                                            <input
                                                type="submit"
                                                value={loading ? 'Signing up...' : 'Sign Up'}
                                                disabled={loading || !agreeTerms}
                                            />
                                        </div>
                                    </form>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLogin;
