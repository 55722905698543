import React, { useState, useEffect } from 'react';
// import './ProductPolicy.css'; // Import your CSS file

const ProductPolicy = ({ policies, description, product_details }) => {
    // State to manage visibility and text content for each policy
    const [policyStates, setPolicyStates] = useState({
        description: { visible: !!description, description: description || '' },
        productDetail: { visible: !!product_details, description: product_details || '' },
        shippingDate: { visible: false, description: '' },
        cancellation: { visible: false, description: '' },
        exchange: { visible: false, description: '', url: '' },
        alteration: { visible: false, description: '' },
        colorDisclaimer: { visible: false, description: '' },
        compositionCare: { visible: false, description: '' },
    });

    // State to manage which section is currently collapsed
    const [activeCollapse, setActiveCollapse] = useState(null);

    // Effect to handle initial policy data
    useEffect(() => {
        const newPolicyStates = { ...policyStates };

        policies.forEach(policy => {
            switch (policy.Title) {
                case 'SHIPPING DATE':
                    if (policy.Description !== '') {
                        newPolicyStates.shippingDate = { visible: true, description: policy.Description };
                    }
                    break;

                case 'CANCELLATION & REFUND':
                    if (policy.Description !== '') {
                        newPolicyStates.cancellation = { visible: true, description: policy.Description };
                    }
                    break;

                case 'EXCHANGE & RETURN POLICY':
                    if (policy.Description !== '') {
                        if (policy.Description.includes('https://') || policy.Description.includes('www.')) {
                            newPolicyStates.exchange = {
                                visible: true,
                                description: 'Click here to view our return policy.',
                                url: policy.Description
                            };
                        } else {
                            newPolicyStates.exchange = { visible: true, description: policy.Description };
                        }
                    }
                    break;

                case 'ALTERATION POLICY':
                    if (policy.Description !== '') {
                        newPolicyStates.alteration = { visible: true, description: policy.Description };
                    }
                    break;

                case 'COLOR & PRODUCT DISCLAIMER':
                    if (policy.Description !== '') {
                        newPolicyStates.colorDisclaimer = { visible: true, description: policy.Description };
                    }
                    break;

                case 'COMPOSITION CARE':
                    if (policy.Description !== '') {
                        newPolicyStates.compositionCare = { visible: true, description: policy.Description };
                    }
                    break;

                default:
                    break;
            }
        });

        setPolicyStates(newPolicyStates);
    }, [policies]);

    const handleCollapseToggle = (section) => {
        setActiveCollapse(prevState => prevState === section ? null : section);
    };

    return (
        <div>
            {/* Description Section */}
            {policyStates.description.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('description')}
                    >
                        Description <span>{activeCollapse === 'description' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'description' ? 'active-content' : ''}`}>
                        <p dangerouslySetInnerHTML={{ __html: policyStates.description.description }}></p>
                    </div>
                </div>
            )}

            {/* Product Detail Section */}
            {policyStates.productDetail.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('productDetail')}
                    >
                        Product Detail <span>{activeCollapse === 'productDetail' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'productDetail' ? 'active-content' : ''}`}>
                        <p dangerouslySetInnerHTML={{ __html: policyStates.productDetail.description }}></p>
                    </div>
                </div>
            )}

            {/* Shipping Date Section */}
            {policyStates.shippingDate.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('shippingDate')}
                    >
                        Shipping Date <span>{activeCollapse === 'shippingDate' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'shippingDate' ? 'active-content' : ''}`}>
                        {activeCollapse === 'shippingDate' && (
                            <p dangerouslySetInnerHTML={{ __html: policyStates.shippingDate.description }}></p>
                        )}
                    </div>
                </div>
            )}

            {/* Cancellation & Refund Section */}
            {policyStates.cancellation.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('cancellation')}
                    >
                        Cancellation & Refund <span>{activeCollapse === 'cancellation' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'cancellation' ? 'active-content' : ''}`}>
                        {activeCollapse === 'cancellation' && (
                            <p dangerouslySetInnerHTML={{ __html: policyStates.cancellation.description }}></p>
                        )}
                    </div>
                </div>
            )}

            {/* Exchange & Return Policy Section */}
            {policyStates.exchange.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('exchange')}
                    >
                        Exchange & Return Policy <span>{activeCollapse === 'exchange' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'exchange' ? 'active-content' : ''}`}>
                        {activeCollapse === 'exchange' ? (
                            policyStates.exchange.url ? (
                                <p>
                                    <a
                                        href="#"
                                        onClick={() => window.open(policyStates.exchange.url, '_blank')}
                                        style={{ textDecoration: 'underline', color: '#007BFF' }}
                                    >
                                        {policyStates.exchange.description}
                                    </a>
                                </p>
                            ) : (
                                <p dangerouslySetInnerHTML={{ __html: policyStates.exchange.description }}></p>
                            )
                        ) : null}
                    </div>
                </div>
            )}

            {/* Alteration Policy Section */}
            {policyStates.alteration.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('alteration')}
                    >
                        Alteration Policy <span>{activeCollapse === 'alteration' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'alteration' ? 'active-content' : ''}`}>
                        {activeCollapse === 'alteration' && (
                            <p dangerouslySetInnerHTML={{ __html: policyStates.alteration.description }}></p>
                        )}
                    </div>
                </div>
            )}

            {/* Color & Product Disclaimer Section */}
            {policyStates.colorDisclaimer.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('colorDisclaimer')}
                    >
                        Color & Product Disclaimer <span>{activeCollapse === 'colorDisclaimer' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'colorDisclaimer' ? 'active-content' : ''}`}>
                        {activeCollapse === 'colorDisclaimer' && (
                            <p dangerouslySetInnerHTML={{ __html: policyStates.colorDisclaimer.description }}></p>
                        )}
                    </div>
                </div>
            )}

            {/* Composition Care Section */}
            {policyStates.compositionCare.visible && (
                <div className="collapsible-container">
                    <button
                        className="toggle-button"
                        onClick={() => handleCollapseToggle('compositionCare')}
                    >
                        Composition Care <span>{activeCollapse === 'compositionCare' ? '-' : '+'}</span>
                    </button>
                    <div className={`content ${activeCollapse === 'compositionCare' ? 'active-content' : ''}`}>
                        {activeCollapse === 'compositionCare' && (
                            <p dangerouslySetInnerHTML={{ __html: policyStates.compositionCare.description }}></p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductPolicy;
