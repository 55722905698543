import React, { useState, useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import api from '../api'; // Assuming 'api' is a configured instance of Axios or similar
import { useAuth } from '../context/AuthContext'; 
const MyOrder = () => {
    const [orders, setOrders] = useState([]);
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const api_token = localStorage.getItem('apiToken');
                const response = await api.get('/commerce/get_user_receipts', {
                    params: {
                        api_token: api_token,
                        order_id: '',
                    },
                });
                const data = response.data;
                if (response.data.status === 401) {
                    logout();
                    navigate('/home');
                }
                // Transform data by only selecting the first item in the item_list
                const transformedOrders = data.receipt_data.map(receipt => {
                    const firstItem = receipt.item_list[0]; // Get first item from the item_list
                    return {
                        order_id: receipt.order_id,
                        quantity: firstItem.quantity,
                        price: firstItem.unit_price,
                        order_number: firstItem.order_num,
                        order_date: new Date(receipt.order_date),
                        delivery_date: new Date(firstItem.ship_eta),
                        product_image: firstItem.product_url,
                        product_title: firstItem.product_title,
                        color: firstItem.color_name,
                        size: firstItem.size_name,
                        total_price_inc_tax: receipt.total_price_inc_tax,
                        total_discount: receipt.total_discount
                    };
                });

                setOrders(transformedOrders);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchOrders();
    }, []);

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="main-content">
            <div className="container">
                <h2>My Order</h2>
                <div id="order" className='my-order-det'>
                    <table className="cart">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Order Number</th>
                                <th>Order Date</th>
                                <th>Delivery Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, index) => (
                                <tr key={index}>
                                    <td className="order">
                                        <Link to={`/order/${order.order_id}`} className="order-det">
                                            <img src={order.product_image} alt={order.product_title} />
                                            <div className="order-attribute">
                                                <h5>{order.product_title}</h5>
                                                <span>Colour: {order.color}</span>
                                                <span>Size: {order.size}</span>
                                            </div>
                                        </Link>
                                    </td>
                                    <td className="qty">{order.quantity}</td>
                                    <td className="price">${order.price.toFixed(2)}</td>
                                    <td className="order-number">{order.order_id}</td>
                                    <td className="order-date">{formatDate(order.order_date)}</td>
                                    <td className="delivery-date">{formatDate(order.delivery_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MyOrder;
