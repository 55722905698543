// useAuth.js
import { useState } from 'react';
import api from '../api'; // Adjust the path as necessary

export const useAuth = () => {
    const [user, setUser] = useState(null);

    const login = async (token, role) => {
        // Handle login logic
    };

    return { user, login };
};
