import React from 'react';
import { Link } from 'react-router-dom';
// import './UserOrder.css'; // Assuming you have a corresponding CSS file

const UserOrder = () => {
    return (
        <div className="main-content not-home">
            <div className="container order-pg">
                <div id="order">
                    <ul className="order-menu">
                        <li className="active"><Link to="#">My Order</Link></li>
                        <li><Link to="/my-gift">My Gift</Link></li>
                    </ul>
                    <table className="cart">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Quality</th>
                                <th>Price</th>
                                <th>Order number</th>
                                <th>Order date</th>
                                <th>Delivery Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="order">
                                    <div className="order-det">
                                        <img src="/images/product-1.jpg" alt="Product 1" />
                                        <div className="order-attribute">
                                            <h5>Tailored Stretch</h5>
                                            <span>Colour: orange </span>
                                            <span>Size: XL</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="qty">1</td>
                                <td className="price">$999.00</td>
                                <td className="order-number">2345678901</td>
                                <td className="order-date">March 10, 2024</td>
                                <td className="delivery-date">March 18, 2024</td>
                            </tr>
                            <tr>
                                <td className="order">
                                    <div className="order-det">
                                        <img src="/images/product-2.jpg" alt="Product 2" />
                                        <div className="order-attribute">
                                            <h5>Tailored Stretch</h5>
                                            <span>Colour: orange </span>
                                            <span>Size: XL</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="qty">2</td>
                                <td className="price">$999.00</td>
                                <td className="order-number">2345678901</td>
                                <td className="order-date">March 10, 2024</td>
                                <td className="delivery-date">March 18, 2024</td>
                            </tr>
                            <tr>
                                <td className="order">
                                    <div className="order-det">
                                        <img src="/images/product-3.jpg" alt="Product 3" />
                                        <div className="order-attribute">
                                            <h5>Tailored Stretch</h5>
                                            <span>Colour: orange </span>
                                            <span>Size: XL</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="qty">1</td>
                                <td className="price">$999.00</td>
                                <td className="order-number">2345678901</td>
                                <td className="order-date">March 10, 2024</td>
                                <td className="delivery-date">March 18, 2024</td>
                            </tr>
                            <tr>
                                <td className="order">
                                    <div className="order-det">
                                        <img src="/images/product-4.jpg" alt="Product 4" />
                                        <div className="order-attribute">
                                            <h5>Tailored Stretch</h5>
                                            <span>Colour: orange </span>
                                            <span>Size: XL</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="qty">2</td>
                                <td className="price">$999.00</td>
                                <td className="order-number">2345678901</td>
                                <td className="order-date">March 10, 2024</td>
                                <td className="delivery-date">March 18, 2024</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserOrder;
