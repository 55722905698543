import React from 'react';
import './LoadMore.css';

const LoadMore = ({ loadedItemCount, totalItems, onLoadMore, loadingMore }) => {
    return (
        <div>
            {/* Progress bar section */}
            {!loadingMore && loadedItemCount < totalItems && (
                <div className="container_j8XTO">
                    <p className="label_Ph1fi">
                        You've viewed {loadedItemCount} of {totalItems} products
                    </p>
                    <progress
                        max={totalItems}
                        value={loadedItemCount}
                        className="progressBar_anIzC"
                        aria-hidden="true"
                    ></progress>
                </div>
            )}

            {/* Loading spinner */}
            {loadingMore && (
                <div className="loading-spinner">
                    <img src="/images/circle-1700_256.gif" alt="Loading..." />
                </div>
            )}

            {/* View More button */}
            {!loadingMore && loadedItemCount < totalItems && (
                <button onClick={onLoadMore} className="load-more-button">
                    View More
                </button>
            )}
        </div>
    );
};

export default LoadMore;
