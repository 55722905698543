import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { useAuth } from '../../context/AuthContext';

const Coupon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { couponID: defaultCouponID } = location.state || {};

    console.log('Location object:', location);
    console.log('defaultCouponID:', defaultCouponID);

    const { apiToken,logout } = useAuth();
    const [coupons, setCoupons] = useState([]);
    const [error, setError] = useState('');
    const [appliedCouponID, setAppliedCouponID] = useState(defaultCouponID);

    // Function to fetch coupon details
    const fetchCouponDetails = async () => {
        try {
            const response = await api.get('/coupon/get_user_coupons', {
                params: {
                    api_token: localStorage.getItem('apiToken'),
                    status: 'active',
                },
            });

            console.log(response, 'response');
            console.log(response.data, 'response.data');
            if (response.data.status === 200) {
                setCoupons(response.data.data);
            }
            else  if (response.data.status === 401) {
                logout();
                navigate('/home');            }
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Failed to fetch coupon details:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchCouponDetails();
    }, []);

    const handleApplyCoupon = async (coupon) => {
        try {
            const response = await api.post('/cart/apply_coupon_discount', {
                api_token: localStorage.getItem('apiToken'),
                coupon_code: coupon.Name,
                order_num: localStorage.getItem('order_num'),
            });

            if (response.data.status === 200 && response.data.data) {
                setAppliedCouponID(coupon.coupon_owner_id); // Set applied coupon ID
                await fetchCouponDetails(); // Refresh the coupon list
                navigate('/cart'); // Navigate to cart after applying coupon
            }
            else  if (response.data.status === 401) {
                logout();
                navigate('/home');            }
            else {
                throw new Error(response.data.message || 'Failed to apply coupon');
            }
        } catch (error) {
            console.error('Failed to apply coupon:', error);
            setError('Failed to apply coupon. Please try again.');
        }
    };

    const handleRemoveCoupon = async () => {
        try {
            const response = await api.post('/cart/apply_coupon_discount', {
                api_token: localStorage.getItem('apiToken'),
                coupon_code: '',
                order_num: localStorage.getItem('order_num'),
            });

            if (response.data.status === 200 && response.data.data) {
                setAppliedCouponID(null); // Clear applied coupon ID
                await fetchCouponDetails(); // Refresh the coupon list
            } 
            else  if (response.data.status === 401) {
                logout();
                navigate('/home');            }
            else {
                throw new Error(response.data.message || 'Failed to remove coupon');
            }
        } catch (error) {
            console.error('Failed to remove coupon:', error);
            setError('Failed to remove coupon. Please try again.');
        }
    };

    return (
        <div className="main-content not-home">
            <div className="container coupons">
                {error && <p className="error">{error}</p>}
                <ul>
                    {coupons.map((coupon) => (
                        <li key={coupon.coupon_owner_id} data-coupon_id={coupon.coupon_owner_id}>
                            <div className="status">
                            {coupon.msg_1 ? (
                                    <a href="#">+ More</a>
                                ) : null}
                                <span className={`cp-sts ${coupon.status}`}>
                                    {coupon.status}
                                </span>
                            </div>
                            <div className="title">
                                <h4>{coupon.Name} ({coupon.referral_name || 'Referral'})</h4>
                                <p>{coupon.heading_1}</p>
                                <p>{coupon.heading_2}</p>
                                {coupon.description && <p>{coupon.description}</p>}
                                {coupon.msg_3 && <p>{coupon.msg_3}</p>}
                            </div>
                            <div className="apply">
                                {appliedCouponID === coupon.coupon_owner_id ? (
                                    <button type="button" onClick={handleRemoveCoupon}>Remove</button>
                                ) : (
                                    <button type="button" onClick={() => handleApplyCoupon(coupon)}>Apply</button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Coupon;
