import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import LazyLoadImage from '../LazyLoadImage'; // Adjust the path if necessary
import api from '../../api';
import { useAuth } from '../../context/AuthContext'; 
const RelatedProducts = ({ storeUuid, sui }) => {
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { logout } = useAuth();

    useEffect(() => {
        if (!storeUuid) return;

        const fetchRelatedProducts = async () => {
            setLoading(true); // Start loading

            try {
                const api_token = localStorage.getItem('apiToken');
                const response = await api.get(`/sku/get_related_sku`, {
                    params: {
                        api_token: api_token,
                        sui,
                        store_uuid: storeUuid,
                    },
                });
                 if (response.status === 401) {
                    logout();
                }
                else if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                if (!Array.isArray(response.data.sku_data)) {
                    throw new Error('Unexpected response format for related products');
                }

                setRelatedProducts(response.data.sku_data);
                setError(null); // Clear any previous errors
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchRelatedProducts();
    }, [storeUuid, sui]);

    if (loading) {
        return (
            <div className="container">
                {/* <h2>You Might Also Like</h2> */}
                <Skeleton height={40} width={300} style={{marginBottom:'15px'}} />

                <div className="also-like pr-dt">
                    <ul>
                        {Array.from({ length: 8 }).map((_, index) => (
                            <li key={index}>
                                <Skeleton height={430} width={275} />
                                <Skeleton height={30} width={220} />
                                <Skeleton height={20} width={100} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!relatedProducts.length) {
        return <p>No related products found</p>;
    }

    return (
        <div className="container">
            <h2>You Might Also Like</h2>
            <div className="also-like pr-dt">
                <ul>
                    {relatedProducts.map((relatedProduct) => {
                        const {
                            image_url,
                            image_url_thumb,
                            sku_title,
                            display_final_price,
                            display_currency_symbol,
                            sui,
                            display_total_price,
                            sku_discount
                        } = relatedProduct;
                        const productLink = `/product/${sui}`;

                        return (
                            <li key={sui}>
                                {/* Use onClick with window.location.href for full page reload */}
                                <a
                                    href={productLink}
                                    className='img-box'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = productLink;
                                    }}
                                >
                                    <LazyLoadImage
                                        thumbnailSrc={image_url_thumb}
                                        fullImageSrc={image_url}
                                        alt={sku_title || 'Product'}
                                        onError={(e) => (e.target.src = image_url_thumb || 'https://via.placeholder.com/150')}
                                    />
                                </a>
                                <div className='description content'>
                                    <h4>
                                        <a
                                            href={productLink}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = productLink;
                                            }}
                                        >
                                            {sku_title || 'Product Title'}
                                        </a>
                                    </h4>
                                    <div className="price-value">
                                        <>
                                            {display_total_price !== display_final_price && (
                                                <span className="strike_price">
                                                    {`${display_currency_symbol}${display_total_price}`}
                                                </span>
                                            )}
                                            <span className="final_price">
                                                {`${display_currency_symbol}${display_final_price}`}
                                            </span>
                                            {display_total_price !== display_final_price && (
                                                <span className="discount">
                                                    {`${sku_discount}% off`}
                                                </span>
                                            )}
                                        </>
                                    </div>
                                </div>
                                <ul className="color">
                                    {/* Example colors, replace with dynamic colors if available */}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

RelatedProducts.propTypes = {
    storeUuid: PropTypes.string.isRequired,
    sui: PropTypes.string.isRequired,
};

export default RelatedProducts;
