import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton CSS

const SubCategoryList = ({ categoryData, onSubCategoryChange, isLoading }) => {
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();
    const [selectedSubCategory, setSelectedSubCategory] = useState(sub_category_id);

    // Handle radio button change
    const handleRadioChange = (subCategoryId) => {
        if (window.jQuery) {
            window.jQuery('.filter-option').find('.filters__item-title').find('.filters__count').text('');
            window.jQuery('.filters__listings').find('ul li').each(function() {
                window.jQuery(this).removeClass('active');
            });
        }
        setSelectedSubCategory(subCategoryId);
        onSubCategoryChange(subCategoryId);
    };

    useEffect(() => {
        setSelectedSubCategory(sub_category_id || '');
    }, [sub_category_id]);

    // Check if categoryData is null or undefined before rendering
    if (isLoading) {
        // Render skeleton while loading
        return (
            <div className="sub-category-list">
                <h4>Sub Category</h4>
                <ul>
                    {/* Skeletons for loading state */}
                    {[...Array(5)].map((_, index) => (
                        <li key={index}>
                          
                            <Skeleton height={40} width={300} />
                           
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    if (!categoryData || categoryData.length === 0) {
        return null; // Handle empty or null data gracefully
    }

    return (
        <div className="sub-category-list">
            <h4>Sub Category</h4>
            <ul>
                {categoryData.map((item) => (
                    <li
                        key={item.sub_category_id}
                        className={`${
                            selectedSubCategory == item.sub_category_id || sub_category_id == item.sub_category_id
                                ? 'active'
                                : ''
                        }`}
                    >
                        <label>
                            <input
                                type="radio"
                                name="subCategory"
                                value={item.sub_category_id}
                                checked={selectedSubCategory == item.sub_category_id}
                                onChange={() => handleRadioChange(item.sub_category_id)}
                            />
                            {item.sub_category_name}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SubCategoryList;
