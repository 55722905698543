import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import ProductList from './ProductList'; // Import the ProductList component
import LoadMore from './LoadMore/LoadMore'; // Import the LoadMore component
import { useAuth } from '../context/AuthContext'; 
import SortSelect from './multiSelect/SortSelect';

const ITEMS_PER_PAGE = 54; // Number of items per load

const LatestArrivalsMain = ({
    apiEndpoint = '/tags/get_sku_list_for_tag',
    pageTitle = '',
}) => {
    const { logout } = useAuth();

    const { tag_id, tag_type } = useParams();
    const [products, setProducts] = useState([]);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedItemCount, setLoadedItemCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [sortOrder, setSortOrder] = useState('');
    const defaultImage = 'https://via.placeholder.com/150';
    const navigate = useNavigate();
    const [selectedSort, setSelectedSort] = useState('');
    const [sortOption, setSortOption] = useState('');

    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
        sortOptions: [
            { value: 'low', label: 'Price Low To High' },
            { value: 'high', label: 'Price High To Low' },
        ]
    });
    // Function to fetch products from the API
    const fetchProducts = async (append = false, currentSortOrder = '') => {
        try {
            if (!append) {
                setLoading(true);
                setLoadedItemCount(0);  // Reset loaded items count when not appending
            } else {
                setLoadingMore(true);
            }

            console.log(`Fetching products... (append: ${append})`);

            const api_token = localStorage.getItem('apiToken');
            const response = await api.get(apiEndpoint, {
                params: {
                    api_token: api_token,
                    tag_type: tag_type,
                    tag_id: tag_id,
                    page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),
                    per_page: ITEMS_PER_PAGE,
                    sort: currentSortOrder,
                },
            });
             if (response.data.status === 401) {
                logout();
                           navigate('/home');
            }
           else if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = response.data;
            const processedProducts = data.tags_data;
            // ?.map((item) => ({
            //     id: item.tag_id,
            //     image: item.image_url || defaultImage,
            //     thumbnail: item.image_url_thumb || defaultImage,
            //     alt: item.product_title,
            //     title: item.product_title,
            //     price: item.display_final_price,
            //     currencySymbol: item.display_currency_symbol,
            //     inStock: item.in_stock,
            //     sui: item.sui,
            //     store_name: item.store_name,
            //     store_uuid: item.store_uuid,
            //     display_total_price: item.display_total_price,
            //     display_final_price: item.display_final_price,
            //     display_discount: item.display_discount,
            // })) || []
            // ;

            setProducts((prevProducts) =>
                append ? [...prevProducts, ...processedProducts] : processedProducts
            );

            if (!append) {
                setOriginalProducts(processedProducts);
            }

            const newLoadedCount = append ? loadedItemCount + processedProducts.length : processedProducts.length;
            setLoadedItemCount(newLoadedCount);
            setTotalItems(data.tags_items_total_count);
            // setFilterOptions({
            //     ...filterOptions,
            //     sizeOptions: sizeListResponse_sizes_data,
            //     priceOptions: PriceListResponse_price_band,
            //     colorOptions: colorListResponse_colors_data,
            // });
            console.log(`New loaded item count: ${newLoadedCount}`);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        fetchProducts(false, sortOrder);
    }, [apiEndpoint, tag_id, tag_type, sortOrder]); // Ensure these dependencies are correctly set

    useEffect(() => {
        console.log(loadedItemCount, 'loadedItemCount changed');
    }, [loadedItemCount]);

    const handleProductClick = (sui) => {
        navigate(`/product/${sui}`);
    };

    const handleSortChange = (e) => {
        console.log(e,'handleSortChange');
        setSortOption(e);
        setSortOrder(e);

        setLoadedItemCount(0);
        setProducts([]);
        fetchProducts(false, e); // Fetch products again with the new sort order
    };
   

    const handleClearFilter = (e) => {
        e.preventDefault();
        setSortOrder('');
        setProducts(originalProducts);
        fetchProducts(false, '');
        setSelectedSort([]);

    };
 // Handle clearing all filters
//  const handleClearFilters = () => {
//     setSelectedSort([]);
//     setSelectedSize([]);
//     setSelectedPrice([]);
//     setSelectedColors([]);
//     onSortChange([]);
//     onSizeChange([]);
//     onPriceChange([]);
//     onColorChange([]);
// };

    const loadMoreProducts = () => {
        fetchProducts(true, sortOrder);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="main-content">
            <div className="wear-offer latest-arrival">
                <div className="container">
                    <h2>{pageTitle}</h2>
                    <div className="sort-row">
                        <div className="sort-by">
                            {/* <span>Sort By</span>
                            <select
                                name="sort"
                                id="sort"
                                onChange={handleSortChange}
                                value={sortOrder}
                            >
                                <option value="">Sort By</option>
                                <option value="low">Price Low To High</option>
                                <option value="high">Price High To Low</option>
                            </select> */}
                               <SortSelect
                    onChange={(value) => {
                        setSelectedSort(value);
                        handleSortChange(value);
                    }}
                    data={filterOptions.sortOptions}
                    selectedValue={selectedSort}
                />
                        </div>
                        <div className="clear">
                            <a href="#" onClick={handleClearFilter}>
                                CLEAR
                            </a>
                        </div>
                    </div>

                    {/* Use ProductList Component Here */}
                    <ProductList
                        products={products}
                        loading={loading}
                        loadedItemCount={loadedItemCount}
                        ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                        defaultImage={defaultImage}
                        handleProductClick={handleProductClick}
                    />

                    {/* Use LoadMore Component Here */}
                    <LoadMore
                        loadedItemCount={loadedItemCount}
                        totalItems={totalItems}
                        onLoadMore={loadMoreProducts}
                        loadingMore={loadingMore}
                    />
                </div>
            </div>
        </div>
    );
};

export default LatestArrivalsMain;
