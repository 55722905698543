import React, { useState, useEffect } from 'react';
import { useLocation, useParams ,useNavigate} from 'react-router-dom';

import { useAuth } from '../../context/AuthContext'; 
import api from '../../api'; // Assuming 'api' is a configured instance of Axios or similar

const PaymentSuccess = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    const [orderDetail, setOrderDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiToken = localStorage.getItem('apiToken');
    const order_id = location.state?.order_id;
    // const { order_id, tag_type } = useParams();
    console.log('order_id', order_id);


    useEffect(() => {
        const fetchOrderDetail = async () => {
            // if (!orderId) {
            //     setError('Order ID not found.');
            //     setLoading(false);
            //     return;
            // }

            try {
                const response = await api.get(`/commerce/get_user_receipts`, {
                    params: {
                        api_token: apiToken,
                        order_id: order_id
                        // order_id: orderId
                    }
                });
                const data = response.data;
                 if (response.data.status === 401) {
                    logout();
                    navigate('/home');
return;
                }
                else if (data.receipt_data && data.receipt_data.length > 0) {
                    const orderData = data.receipt_data[0];
                    const detailedOrder = {
                        order_id: orderData.order_id,
                        order_date: orderData.order_date,
                        total_price_inc_tax: orderData.total_price_inc_tax,
                        total_tax: orderData.total_tax,
                        total_unit_price: orderData.total_unit_price,
                        total_discount: orderData.total_discount,
                        total_amount_paid: orderData.total_amount_paid,
                        shipping: orderData.shipping,
                        coupon_value: orderData.coupon_value,
                        items: orderData.item_list.map(item => ({
                            order_number: item.order_num,
                            product_title: item.product_title,
                            product_image: item.product_url,
                            quantity: item.quantity,
                            price: item.unit_price,
                            final_price: item.final_price,
                            store_name: item.store_name,
                            delivery_date: item.ship_eta,
                            currency_symbol: orderData.currency_symbol,
                        }))
                    };

                    setOrderDetail(detailedOrder);
                } else {
                    setError('No order details found.');
                }
            } catch (error) {
                console.error('Error fetching order detail:', error);
                setError('An error occurred while fetching order details.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetail();

    }, [order_id, apiToken]);
    console.log('orderDetail', orderDetail);

    if (error) {
        return <div>{error}</div>;
    }

    if (!orderDetail || !orderDetail.items || orderDetail.items.length === 0) {
        return <div>No order details available.</div>;
    }

    return (
        <div className="order-detail container order-details-pg">
            <h2>Order Details</h2>
            {orderDetail && (
                <div className="new-orderrow">
                    <div className='order-details'>
                        <div className="store-order">
                            <div className="top-details">
                                <div className="order-icon">
                                    {/* <img src={orderDetail.items[0].product_image} alt="" /> */}
                                    <img src="/images/order-cart.png" alt="" />
                                </div>
                                <div className="order-store">
                                    <ul>
                                        <li>
                                            <span><strong>Store:</strong></span>
                                            <span><strong>{orderDetail.items[0].store_name}</strong></span>
                                        </li>
                                        <li>
                                            <span>Order number: </span>
                                            <span>{orderDetail.order_id}</span>
                                        </li>
                                        <li>
                                            <span>Order Date: </span>
                                            <span>{new Date(orderDetail.order_date).toLocaleDateString()}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {orderDetail.items.map((item, index) => (
                                <div className="product-row" key={index}>
                                    <div className="img-block">
                                        <img src={item.product_image} alt={item.product_title} />
                                    </div>
                                    <div className="product-details">
                                        <div className="pro-name"><h4>{item.product_title}</h4></div>
                                        <div className="color"><span>Colour:</span> {item.color_name || 'N/A'}</div>
                                        <div className="size"><span>Size:</span> {item.size_name || 'N/A'}</div>
                                        <div className="quantity"><span>Qty:</span> {item.quantity}</div>
                                        <div className="final-amount">
                                            <span>{item.currency_symbol}{item.final_price}</span>
                                            <span className="main-pr">
                                                {item.currency_symbol}{item.price}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="items-details">
                        <table>
                            <thead>
                                <tr>
                                    <th align="left">Item Total Price (excluding tax)</th>
                                    <th align="left">{orderDetail.items[0].currency_symbol}{orderDetail.total_unit_price}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2" height="20"></td>
                                </tr>
                                <tr>
                                    <td>Taxes</td>
                                    <td>{orderDetail.items[0].currency_symbol}{orderDetail.total_tax}</td>
                                </tr>
                                <tr>
                                    <td>Total (Including tax)</td>
                                    <td>{orderDetail.items[0].currency_symbol}{orderDetail.total_price_inc_tax}</td>
                                </tr>
                                <tr>
                                    <td>Shipping</td>
                                    <td>{orderDetail.items[0].currency_symbol}{orderDetail.shipping}</td>
                                </tr>
                                <tr>
                                    <td>Coupon Applied</td>
                                    <td>-{orderDetail.items[0].currency_symbol}{orderDetail.coupon_value}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total paid</td>
                                    <td>{orderDetail.items[0].currency_symbol}{orderDetail.total_amount_paid}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentSuccess;