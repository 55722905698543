import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import api from '../api';
import Pagination from './pagination/Pagination';
import LazyLoadImage from './LazyLoadImage'; // Adjust the path as needed LazyLoadImage
import Skeleton from 'react-loading-skeleton';  // Import skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles

const MyProfileFavorites = () => {
    const { logout, currentUser } = useAuth(); // Destructure currentUser to check authentication
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // Start with page 0
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(54); // Default items per page
    const defaultImage = 'https://via.placeholder.com/150';
    const navigate = useNavigate();
    console.log('tested')
    const userRole = localStorage.getItem('userRole');
    console.log('userRole',userRole);

    // Redirect to login if user is not authenticated or role is not 'shopper'
    useEffect(() => {

        if (userRole !== 'shopper') {
            navigate('/login'); // Redirect to login page
            return;
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                setLoading(true);
                const api_token = localStorage.getItem('apiToken');

                // Ensure the API token exists before making the API call
                if (!api_token) {
                    setError('User not authenticated.');
                    return;
                }

                const response = await api.get('/favorite/get_favorite', {
                    params: {
                        api_token: api_token,
                        page: currentPage, // Sending the current page as a parameter
                    },
                });

                console.log("API Response:", response.data); // Log the response data

                // Check for application-specific status
                if (response.data.status === 401) {
                    logout();
                    navigate('/home');
                } else if (response.data.status !== 200) {
                    throw new Error(`No favorites available.`);
                }

                // Ensure user_favorite exists and is an array
                if (Array.isArray(response.data.user_favorite)) {
                    setFavorites(response.data.user_favorite);
                } else {
                    throw new Error(`Expected user_favorite to be an array but got: ${typeof response.data.user_favorite}`);
                }

                setTotalPages(Math.ceil(response.data.total_count / perPage)); // Calculate total pages
            } catch (error) {
                // This will handle both network errors and application-specific errors
                setError(error.message);
            } finally {
                setLoading(false); // Stop loading after the data is fetched
            }
        };

        fetchFavorites();
    }, [currentPage, perPage, currentUser]); // Removed apiToken since it is now accessed directly in the effect

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleProductClick = (sui) => {
        navigate(`/product/${sui}`);
    };

    return (
        <div className="container">
            <div className="also-like latest my-profile-sec">
                <ul>
                    {loading ? (
                        Array.from({ length: perPage }).map((_, index) => (
                            <li key={index} className="skeleton-wrapper">
                                <div className="img-box">
                                    <Skeleton height={430} /> {/* Placeholder for image */}
                                </div>
                                <div className="description content">
                                    <h4>
                                        <Skeleton width={150} /> {/* Placeholder for title */}
                                    </h4>
                                    <div className="price-value">
                                        <Skeleton width={100} /> {/* Placeholder for price */}
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : favorites.length > 0 ? (
                        favorites.map((product) => (
                            <li key={product.reference_id} onClick={() => handleProductClick(product.reference_id)}>
                                <Link to="#" className="img-box">
                                    <LazyLoadImage
                                        thumbnailSrc={product.image_url_thumb}
                                        fullImageSrc={product.image_url}
                                        alt={product.product_title}
                                        onError={(e) => (e.target.src = defaultImage)}
                                    />
                                    {product.in_stock <= 0 && <span className="sold-out">Sold Out</span>}
                                </Link>
                                <div className="description content">
                                    <h4>
                                        <Link to={`/product/${product.reference_id}`}>{product.product_title}</Link>
                                    </h4>
                                    <div className="price-value">
                                        {product.display_total_price !== product.display_final_price ? (
                                            <>
                                                <span className="strike_price">
                                                    {`${product.display_currency_symbol}${product.display_total_price}`}
                                                </span>
                                                <span className="final_price">
                                                    {`${product.display_currency_symbol}${product.display_final_price}`}
                                                </span>
                                                <span className="discount">
                                                    {`${product.platform_discount}% off`}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="final_price">
                                                {`${product.display_currency_symbol}${product.display_final_price}`}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <p>No favorites available.</p>
                    )}
                </ul>
                {totalPages > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage + 1} // Pagination starts from 1
                        onPageChange={(pageNumber) => handlePageChange(pageNumber - 1)} // Adjust for 0-based index
                    />
                )}
            </div>
        </div>
    );
};

export default MyProfileFavorites;
