import React, { useState, useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api'; // Ensure this uses Axios
import { useAuth } from '../../context/AuthContext'; 
import productDynamicLink from './productDynamicLink';
import LoginPrompt from '../loginPrompt/LoginPrompt'; // Import the modal component
import Modal from '../modal/Modal'; // Import the Modal component
import { ToastNotification, notifySuccess, notifyError } from '../toastNotification/ToastNotification';

const ProductIcons = ({
    isWishlist: initialIsWishlist,
    isFavorite: initialIsFavorite,
    videoData,
    storeUuid,
    itemId,
    color_id,
    size_id,
    systemName,
    imageData,
    description,
    title,
}) => {
    const { logout, isLoggedIn } = useAuth(); // Get isLoggedIn from auth context
    const navigate = useNavigate();
    const [isWishlist, setIsWishlist] = useState(initialIsWishlist);
    const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false); // State to control the modal visibility
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const api_token = localStorage.getItem('apiToken');
    const userRole = localStorage.getItem('userRole');
    const inputRef = useRef(null); // Create a ref for the input element

    useEffect(() => {
        setIsWishlist(initialIsWishlist);
    }, [initialIsWishlist]);

    useEffect(() => {
        setIsFavorite(initialIsFavorite);
    }, [initialIsFavorite]);

    const handleApiCall = async (apiEndpoint, params) => {
        try {
            const response = await api.post(apiEndpoint, params);
            if (response.status === 401) {
                logout();
                navigate('/home');
            } else if (response.status !== 200) {
                throw new Error('API request failed');
            }
            return response.data;
        } catch (error) {
            console.error(`Error with API call to ${apiEndpoint}:`, error);
        }
    };

    const addToGift = async () => {
        return handleApiCall('/gift/add_item_to_gift_list', {
            api_token,
            color_id: color_id.color_id.toString(),
            influencer_user_id: '0',
            size_id: size_id.size_id.toString(),
            store_uuid: storeUuid,
            sui: itemId,
            system_name: systemName,
        });
    };

    const removeFromGift = async () => {
        return handleApiCall('/gift/remove_gift_item', {
            api_token,
            sui: itemId,
        });
    };

    const addToFavorite = async () => {
        return handleApiCall('/favorite/set_favorite', {
            api_token,
            reference_id: itemId,
            type: 'product',
            influencer_user_id: "0",
            store_uuid: storeUuid,
            system_name: systemName,
        });
    };

    const removeFromFavorite = async () => {
        return handleApiCall('/favorite/remove_favorite', {
            api_token,
            reference_id: itemId,
            type: 'product',
        });
    };

    const handleWishlistToggle = async (event) => {
        event.preventDefault();
        if (!isLoggedIn) { // Check if the user is logged in
            setShowLoginPrompt(true); // Show login prompt
            return; // Exit the function
        }
        const result = isWishlist ? await removeFromGift() : await addToGift();
        if (result) {
            setIsWishlist(!isWishlist);
        }
    };

    const handleVideoIcon = async (event) => {
        event.preventDefault();
        if (videoData.length > 0) {
            setSelectedVideo(videoData[0].streaming_url); // Set selected video to the first one
     


        }
    };

    const handleFavoriteToggle = async (event) => {
        event.preventDefault();
        if (userRole !== 'shopper') { 
            setShowLoginPrompt(true); 
            return; 
        }
        const result = isFavorite ? await removeFromFavorite() : await addToFavorite();
        if (result) {
            setIsFavorite(!isFavorite);
        }
    };

    const handleShare = async () => {
    
        try {
            // Generate dynamic link based on store info
            const dynamicLink = await productDynamicLink(itemId, systemName, storeUuid, imageData, description, title);
            
    
            // 2. Proceed to sharing the link if supported
            if (navigator.share) {
                try {
                    await navigator.share({
                        title: title,
                        text: description,
                        url: dynamicLink,
                    });
                    console.log("Link shared successfully");
                } catch (shareError) {
                    console.error("Error sharing the link:", shareError);
                    // alert("Link sharing failed. However, the link was copied to the clipboard.");
                }
            } else {
                console.log("Web Share API not supported. Only link copied to clipboard.");
            }
    
        } catch (error) {
            console.error("Error creating or sharing dynamic link:", error);
        }
    };
    
      
      
      // Call this function in a useEffect or on a specific user action
      
      
        
    
    const closeLoginPrompt = () => {
        setShowLoginPrompt(false); // Hide the modal
    };

    const closeModal = () => {
        setSelectedVideo(null); // Clear selected video to close the modal
    };
    const copyToClipboard1 = () => {
        // Check if the input element is available
        if (inputRef.current) {
          inputRef.current.select(); // Select the text in the input field
          inputRef.current.setSelectionRange(0, 99999); // For mobile devices
    
          // Use the Clipboard API to copy the text
          navigator.clipboard
            .writeText(inputRef.current.value)
            .then(() => {
              alert('Successfully copied');
            })
            .catch((err) => {
              console.error('Failed to copy: ', err);
              alert('Something went wrong');
            });
        }
      };
    return (
        <div className="product-icons">
            <div className="icons-row">
                <ul>
                    <li className={`gift ${isWishlist ? 'active' : 'inactive'}`}>
                        <a href="#" onClick={handleWishlistToggle}></a>
                    </li>

                    <li className="share">
                        <a href="#" onClick={handleShare} className="icon-button"></a>
                    </li>

                    <li className={`tag savewishlist ${isFavorite ? 'active' : 'inactive'}`}>
                        <a href="#" onClick={handleFavoriteToggle}></a>
                    </li>

                    {videoData.length > 0 && (
                        <li className="watch">
                            <a href="#" onClick={handleVideoIcon}></a>
                        </li>
                    )}
                </ul>
                <div className="store-btn">
                    <ul>
                        <li>
                            <a href="#">
                                <span>
                                    <img src="/images/chat.png" alt="Talk to Store" />
                                </span>
                                Talk to Store
                            </a>
                        </li>
                    </ul>
                    <div className='enter_store'>
                        <a href={`/visit-store/${storeUuid}`}>
                            <span>
                                <img src="/images/storefront.svg" alt="Enter Store" />
                            </span>
                            Enter Store
                        </a>
                        <input
        type="text"
        id="myInput"
        ref={inputRef} // Attach the ref to the input element
        defaultValue="Hello, World!" // Default value for demonstration
     style={{display:'none'}}
     />
      <button onClick={copyToClipboard1} style={{display:'none'}}>Copy Text</button>
                    </div>
                </div>
            </div>

            {/* Render the LoginPrompt modal if showLoginPrompt is true */}
            {showLoginPrompt && <LoginPrompt onClose={closeLoginPrompt} />}

            {/* Modal for displaying the selected video */}
            <Modal
                isOpen={!!selectedVideo}
                onClose={closeModal}
                videoUrl={selectedVideo}
                productDetails = {productDetails}
                videoData={videoData[0]}
            />
        </div>
    );
};

export default ProductIcons;
