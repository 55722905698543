import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../api'; // Import axios or your preferred HTTP client
import { useAuth } from '../../context/AuthContext'; 
import LazyLoadImage from '../LazyLoadImage'; // Adjust the path as needed
import LoadMore from '../LoadMore/LoadMore'; // Import the LoadMore component

const ITEMS_PER_PAGE = 54; // Number of items per page
const defaultImage = 'https://via.placeholder.com/150';

const StoreFilterProducts = ({ storeUuid, loading: propLoading, onClearFilters, sortOption }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(propLoading);
    const [isSuiData, setIsSuiData] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedItemCount, setLoadedItemCount] = useState(0);

    // Function to fetch products from the API
    const fetchProducts = async (append = false) => {
        try {
            if (!storeUuid) return;

            if (!append) {
                setLoading(true);
                setLoadedItemCount(0); // Reset loaded items count only when starting a new fetch
                setCurrentPage(1); // Reset page number if not appending
            } else {
                setLoadingMore(true);
            }

            const api_token = localStorage.getItem('apiToken');
            const currencyCode = localStorage.getItem('currencyCode');

            let response;

            if (sortOption) {
                response = await api.get('/filters/get_skus_for_filter', {
                    params: {
                        api_token,
                        store_uuid: storeUuid,
                        is_private: 0,
                        page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),

                        per_page: ITEMS_PER_PAGE,
                        sort_order: sortOption,
                        currency_code: currencyCode,
                    },
                });
            } else {
                response = await api.get('/sku/get_sku_list', {
                    params: {
                        api_token,
                        store_uuid: storeUuid,
                        is_private: 0,
                        currency_code: currencyCode,

                        page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),

                        per_page: ITEMS_PER_PAGE,
                        sort_order: sortOption,
                    },
                });
            }

            if (response.status === 200) {
                const data = response.data;
                const processedProducts = data.sui_data || data.sku_data || [];
                const totalItems = data.total_count;

                // Append products instead of replacing
                setProducts((prevProducts) =>
                    append ? [...prevProducts, ...processedProducts] : processedProducts
                );

                if (!append) {
                    setProducts(processedProducts);
                }
                const newLoadedCount = append ? loadedItemCount + processedProducts.length : processedProducts.length;

                setLoadedItemCount(newLoadedCount);
                setTotalItems(totalItems);

                console.log(`New loaded item count: ${newLoadedCount}`);

                setIsSuiData(data.sui_data);
                if (append) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            } 
            else if (response.status === 401) {
                logout();
                navigate('/home')

            }
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        fetchProducts(false);
    }, [storeUuid, sortOption]);

    const loadMoreProducts = () => {
        fetchProducts(true); // Append products
    };

    const handleProductClick = (sui) => {
        navigate(`/product/${sui}`);
    };


    if (loading ) {
        return (
            <div id="filter-products">
                <div className="container">
                    <div className="also-like latest">

                    <ul className="skeleton-list">
                        {Array.from({ length: 12}).map((_, index) => (
                            <li key={index}>
                                <Skeleton width={275} height={430} />
                                <h4><Skeleton width={200} /></h4>
                                <div className="price-value">
                                    <Skeleton width={100} />
                                </div>
                            </li>
                        ))}
                    </ul>
                        </div>
                </div>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <div id="filter-products">
            <div className='container'>
                <ul>
                    <li className={activeTab === 'All' ? 'active' : ''}>
                        <a href="#" onClick={(e) => { e.preventDefault(); onClearFilters(); }}>All</a>
                    </li>
                </ul>

                <div className="also-like latest">
                    <ul>
                        {products.map((product) => {
                            const imageUrl = isSuiData ? product.image_url : product.image_url_thumb;
                            const SUI_data = isSuiData ? product.SUI : product.sui;
                            const title = isSuiData ? product.product_title : product.sku_title;
                            const total_price = isSuiData ? product.display_final_price : product.display_final_price;
                            const strike_price = isSuiData ? product.display_total_price : product.display_total_price;
                            const discount = isSuiData ? product.platform_discount : product.sku_discount;
                            const currency_symbol = isSuiData ? product.display_currency_symbol : product.display_currency_symbol;

                            return (
                                <li key={SUI_data || product.id} onClick={() => handleProductClick(SUI_data || product.id)}>
                                    <Link to="#" className='img-box' onClick={(e) => e.preventDefault()}>
                                        <LazyLoadImage
                                            thumbnailSrc={imageUrl || defaultImage}
                                            fullImageSrc={imageUrl || defaultImage}
                                            alt={title || 'Product image'}
                                            onError={(e) => (e.target.src = defaultImage)}
                                        />
                                        {product.in_stock === 0 && (
                                            <span className="sold-out">Sold Out</span>
                                        )}
                                    </Link>

                                    <div className="description content">
                                        <h4>
                                            <Link to={`/product/${SUI_data}`}>{title}</Link>
                                        </h4>
                                        <div className="price-value">
                                            {strike_price && strike_price !== total_price && (
                                                <>
                                                    <span className="strike_price">
                                                        {`${currency_symbol || '$'}${strike_price}`}
                                                    </span>
                                                    <span className="final_price">
                                                        {`${currency_symbol || '$'}${total_price}`}
                                                    </span>
                                                    <span className="discount">
                                                        {`${discount || '0'}% off`}
                                                    </span>
                                                </>
                                            )}
                                            {(!strike_price || strike_price === total_price) && (
                                                <span className="final_price">
                                                    {`${currency_symbol || '$'}${total_price}`}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {/* Use LoadMore Component Here */}
                <LoadMore
                    loadedItemCount={loadedItemCount}
                    totalItems={totalItems}
                    onLoadMore={loadMoreProducts}
                    loadingMore={loadingMore}
                />
            </div>
        </div>
    );
};

export default StoreFilterProducts;
