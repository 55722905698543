import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import LazyLoadImage from './LazyLoadImage';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const defaultImage = 'https://via.placeholder.com/150';

const ProductListing = ({
    products,
    loading,
    loadedItemCount,
    ITEMS_PER_PAGE,
    handleProductClick,
    sortOrder,
}) => {
    // Sorting products based on the sortOrder prop
    const sortedProducts = useMemo(() => {
        console.log('Sorting products:', products); // Debugging log
        if (sortOrder === 'low') {
            return [...products].sort((a, b) => a.price - b.price);
        } else if (sortOrder === 'high') {
            return [...products].sort((a, b) => b.price - a.price);
        }
        return products;
    }, [products, sortOrder]);

    console.log('Rendered products:', sortedProducts); // Debugging log

    return (
        <div className="also-like latest">
            <ul>
                {loading && loadedItemCount === 0 ? (
                    [...Array(ITEMS_PER_PAGE)].map((_, index) => (
                        <li key={`skeleton-${index}`}>
                            <Link to="#" className="img-box">
                                <Skeleton height={400} width={300} />
                                <Skeleton width={100} />
                            </Link>
                            <div className="description content">
                                <Skeleton width={150} />
                                <Skeleton width={100} />
                                <Skeleton width={80} />
                            </div>
                        </li>
                    ))
                ) : sortedProducts.length > 0 ? (
                    sortedProducts.map((product, index) => (
                        <li key={`${product.id}-${index}`} onClick={() => handleProductClick(product.sui)}>
                            <Link to="#" className="img-box">
                                <LazyLoadImage
                                    thumbnailSrc={product.image_url_thumb}
                                    fullImageSrc={product.image_url}
                                    alt={product.alt}
                                    onError={(e) => (e.target.src = defaultImage)}
                                />
                                {(product.inStock === 0 || product.inStock === '0') && (
                                    <span className="sold-out">Sold Out</span>
                                )}
                            </Link>
                            <h4>{product.store_name}</h4>
                            <div className="description content">
                                <h4>
                                    <Link to={`/product/${product.sui}`}>{product.product_title}</Link>
                                </h4>
                                <div className="price-value">
                                    {product.display_total_price !== product.display_final_price ? (
                                        <>
                                            <span className="strike_price">
                                                {`${product.display_currency_symbol}${product.display_total_price}`}
                                            </span>
                                            <span className="final_price">
                                                {`${product.display_currency_symbol}${product.display_final_price}`}
                                            </span>
                                            <span className="discount">
                                                {`${product.display_discount || product.platform_discount}% off`}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="final_price">
                                            {`${product.display_currency_symbol}${product.display_final_price}`}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <p>No products available</p>
                )}
            </ul>
        </div>
    );
};

export default ProductListing;
