import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import './Modal.css';
import api from '../../api';
import shareVideosLink from './shareVideosLink';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Modal = ({ isOpen, onClose, videoUrl, videoData }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const api_token = localStorage.getItem('apiToken');
    const [productDetails, setProductDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Fetch product details based on videoData
    useEffect(() => {
        const fetchProductDetails = async () => {
            if (videoData) {
                const { product_sui, store_uuid } = videoData;

                if (!product_sui || !store_uuid) {
                    console.error('Invalid product details');
                    return;
                }

                try {
                    const response = await api.get('/sku/get_multiple_sku', {
                        params: {
                            api_token,
                            sui_list: product_sui,
                            store_uuid,
                        },
                    });

                    if (response.status === 200) {
                        setProductDetails(response.data.data.data);
                    } else {
                        console.error('API request failed with status:', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching product details:', error);
                }
            }
        };

        fetchProductDetails();
    }, [videoData, api_token]);

    // Handle video playback logic
    useEffect(() => {
        const videoElement = videoRef.current;
        let hls;

        if (isOpen && videoUrl && videoElement) {
            setIsLoading(true); // Start loading when video URL is available
            if (Hls.isSupported() && videoUrl.includes('.m3u8')) {
                hls = new Hls();
                hls.loadSource(videoUrl);
                hls.attachMedia(videoElement);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    videoElement
                        .play()
                        .then(() => {
                            setIsLoading(false); // Stop loading once the video is ready to play
                        })
                        .catch((err) => {
                            console.error('Error playing HLS stream:', err);
                        });
                });
            } else {
                videoElement.src = videoUrl;
                videoElement
                    .play()
                    .then(() => {
                        setIsLoading(false); // Stop loading when the video starts playing
                    })
                    .catch((err) => {
                        console.error('Error playing video:', err);
                    });

                videoElement.onloadedmetadata = () => {
                    setIsLoading(false); // Stop loading when metadata is loaded
                };
            }

            // Unmute the video when modal opens
            videoElement.muted = false;
            setIsMuted(false);

            // Cleanup function for HLS
            return () => {
                if (hls) {
                    hls.destroy();
                }
                videoElement.pause(); // Pause the video when the modal closes
            };
        }

        // Reset video playback and mute state when the modal closes
        if (!isOpen && videoElement) {
            videoElement.muted = true; // Mute the video when modal closes
            setIsPlaying(false);
            setIsMuted(true);
        }
    }, [isOpen, videoUrl]);

    // Synchronize the video element's muted property with isMuted state
    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.muted = isMuted;
        }
    }, [isMuted]);

    const handlePlayPause = () => {
        const videoElement = videoRef.current;
        if (isPlaying) {
            videoElement.pause();
        } else {
            videoElement.play().catch((err) => console.error('Error playing video:', err));
        }
        setIsPlaying(!isPlaying);
    };

    const handleMuteUnmute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const handleShare = async () => {
        const { video_uuid, store_uuid, store_logo } = videoData;

        try {
            const dynamicLink = await shareVideosLink(video_uuid, store_uuid, store_logo);
            
            if (navigator.share) {
                await navigator.share({ url: dynamicLink });
                console.log('Link shared successfully');
            }
        } catch (error) {
            console.error('Error creating or sharing dynamic link:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay video-frame" onClick={onClose} height={500} width={350} >
            <div className="modal-content video-play-data" onClick={(e) => e.stopPropagation()}>
                <div className="video-product-container">
                    {/* Video Section */}
                    <div className="video-section">
                        {/* {isLoading ? (
                            <Skeleton height={500} width={350} />
                        ) : ( */}
                            <video ref={videoRef} autoPlay loop />
                        {/* )} */}
                        <div className="custom-controls" >
                            <button
                                className="control-btn"
                                onClick={onClose}
                            >
                                <img src="/images/close.svg" alt="Close" />
                            </button>

                            <button
                                className="control-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleShare();
                                }}
                            >
                                <img src="/images/share.svg" alt="Share video" />
                            </button>

                            <button
                                className="control-btn"
                                onClick={handleMuteUnmute}
                            >
                                <img
                                    src={isMuted ? '/images/ic_volume_off.png' : '/images/ic_volume_on.png'}
                                    alt={isMuted ? 'Unmute' : 'Mute'}
                                />
                            </button>
                        </div>
                    </div>

                    {/* Products Section */}
                    <div className="products-section">
                        {productDetails.length > 0 &&
                            productDetails.map((product, index) => (
                                <a
                                    href={`/product/${product.SUI}`} // Example URL
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                    className="product-item-circle"
                                >
                                    <img
                                        src={product.product_url_thumb}
                                        alt={product.product_title}
                                        className="product-image-circle"
                                    />
                                </a>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
