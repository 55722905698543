import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import NavMenu from './NavMenu';
import api from '../../api';
import { useAuth } from '../../context/AuthContext';
import CurrencySelector from '../currencySelector/CurrencySelector';  // Import the CurrencySelector component
import { countries } from 'countries-list';
import AlertPopup from '../alert/AlertPopup'; // Import the Alert component
const countryList = Object.values(countries);

const Header = () => {
    const { cartCount, addItemToCart } = useCart();
    const { apiToken, userRole, logout } = useAuth(); // Your auth hook
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [error, setError] = useState(null); // State to handle any errors
    const [isHovered, setIsHovered] = useState(false);
 // State variables to handle the class toggles
 const [navIconOpen, setNavIconOpen] = useState(false);
 const [menuOpen, setMenuOpen] = useState(false);
 const [activeItem, setActiveItem] = useState(null); // Track which menu item is active
 const [visibleSubmenu, setVisibleSubmenu] = useState(null); // Track which submenu is visible

    let api_token = localStorage.getItem('apiToken');

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };
  // Toggle menu open and icon state
  const handleNavIconClick = () => {
    
    // Toggle the nav icon state
    setNavIconOpen((prevState) => !prevState);

    // Toggle the menu state and add/remove class from body
    setMenuOpen((prevState) => {
        const newState = !prevState;

        // Add or remove the class from the body element based on the new state
        if (newState) {
            document.body.classList.add('menuopen');
        } else {
            document.body.classList.remove('menuopen');
        }

        return newState; // Update the menu state
    });
};

    return (
        <header>
            <div className="hdr-top">
                <div className="container">
                    {/* <div className="social-media">
                        <ul>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/instagram.png`} alt="instagram" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="youtube" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/twitter.png`} alt="twitter" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/facebook.png`} alt="facebook" />
                                </Link>
                            </li>
                        </ul>
                    </div> */}

                    <div className="language">
                        <ul>
                            <CurrencySelector />
                            {/* <select className="has-sub-menu sub-cunt" disabled>
                              <option >  Modestrend </option>
                            </select> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="hdr-mid">
                <div className="container">
                    <div className="left-menu">
                        <button id="nav-icon2"
                         className={`nav-icon ${navIconOpen ? 'open active' : ''}`}
                         onClick={handleNavIconClick}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="logo">
                        <Link to='/'>
                            <img src={`${process.env.PUBLIC_URL}/images/topnavi.png`} alt="Logo" />
                        </Link>
                    </div>
                    <div className="search">
                        <input type="text" className="searchTerm" placeholder="Search for items" />
                        <button type="submit" className="searchButton">
                            <img src="/images/search-icon.svg" height={14} width={14} />
                        </button>
                    </div>
                    <div className="right-function">
                        <div className="language">
                            <ul>
                                <CurrencySelector />
                                {/* <select className="has-sub-menu sub-cunt" disabled>
                              <option value="">  Modestrend </option>
                            </select> */}
                            </ul>
                        </div>
                        <ul className="account-icons">
                        <li>
    <Link to="/cart">
        <img src="/images/cart.svg" height={18} width={18} alt="Cart" />
        {cartCount > 0 && (  // Add the correct ternary condition
            <div className="cart-icon">
                <sup>{cartCount}</sup>
            </div>
        )}
    </Link>
</li>

{apiToken && userRole === 'shopper' ? (
    <>
        {/* <li>
            <Link to="#" onClick={handlePopupOpen}>
                <img src="/images/alert.svg" height={18} width={18} alt="Alert" />
            </Link>
        </li> */}
        <li className={`profile ${isHovered ? 'active' : ''}`}>
            <Link to='#'>
                <img src="/images/ic_profile_blue.svg" height={18} width={18} alt="Profile" />
            </Link>
            <ul className='profile-container'>
                <li className='item'>
                    <Link to='/my-favorites'>
                        <span><img src="/images/favorite.png" height={18} width={18} alt="Favorites" /></span>Favorites
                    </Link>
                </li>
                <li className='item'>
                    <Link to='/myorder'>
                        <span><img height={18} width={18} src='/images/my_orders.svg' alt="My Orders" /></span>My Order
                    </Link>
                </li>
                <li className='item'>
                    <button onClick={logout}>
                        <span><img src="/images/logout.svg" height={18} width={18} alt="Logout" /></span>Logout
                    </button>
                </li>
            </ul>
        </li>
    </>
) : (
    <li>
        <Link to='/login'>
            <img src="/images/profile.svg" height={18} width={18} alt="Login" />
        </Link>
    </li>
)}

                        </ul>
                    </div>
                </div>
            </div>
            <div className={`megamenu ${menuOpen ? 'active' : ''}`}>
                <NavMenu apitokenData={api_token} />
            </div>

            {/* Popup component */}
            <AlertPopup isOpen={isPopupOpen} onClose={handlePopupClose} />
        </header>
    );
};

export default Header;
