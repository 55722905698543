import React from 'react';
import './LoginPrompt.css';
const LoginPrompt = ({ onClose }) => {
    return (
        <div className="custom-popup-overlay">
            <div className="custom-popup">
                {/* <h2>Log In Required</h2> */}
                <p>Please Sign Up to continue.</p>
                <div className="popup-actions">
                    <button onClick={onClose}>Close</button>
                    <a href="/login" className="login-button">Log In</a>
                </div>
            </div>
        </div>
    );
};

export default LoginPrompt;
