import React, { useState, useEffect } from 'react';
import './MultiSelector.css';

const MultiInputPrice = ({ data = [], onChange, selectedPriceValue, onPriceClearFilter }) => {
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    // Synchronize internal state with the `selectedValue` prop
    useEffect(() => {
        if (typeof selectedPriceValue === 'string' && selectedPriceValue !== 'null') {
            const [min, max] = selectedPriceValue.split('-');
            setMinPrice(min || ''); // Set minPrice to an empty string if min is undefined
            setMaxPrice(max || ''); // Set maxPrice to an empty string if max is undefined
        } else {
            // If selectedPriceValue is null or 'null', set both minPrice and maxPrice to empty strings
            setMinPrice('');
            setMaxPrice('');
        }
    }, [selectedPriceValue]);
    
    // Handle the price change
    const handlePriceChange = () => {
        // Notify parent component of the price range only if both fields have values
        if (minPrice || maxPrice) {
            onChange(`${minPrice}-${maxPrice}`); // Notify parent with combined value
        } else {
            onChange(null); // Clear the selection if no price is entered
        }
    };

    // Handle clearing the selection
    const clearSelection = () => {
        setMinPrice('');
        setMaxPrice('');
        onChange(null);
        onPriceClearFilter();
    };

    return (
        <div
            className="filters__item--top js-dropdown dropdown dropdown--full"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            <button
                className={`js-dropdown__trigger filters__item-title ${isOpen ? 'btn_open' : ''}`}
                aria-haspopup="dialog"
                role="button"
                aria-expanded={isOpen}
            >
                Price
                <span className="h5 filters__count filters__count-size price_multi">
                    {minPrice || maxPrice ? `(${minPrice} - ${maxPrice})` : ''}
                </span>
                <span className="icon icon--arrow-down icon--sm">
                    <svg viewBox="0 0 16 16" aria-hidden="true" focusable="false" role="presentation">
                        <path d="M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"></path>
                    </svg>
                </span>
            </button>

            <div
                className={`filters__dropdown-menu js-dropdown__menu dropdown__menu ${isOpen ? 'open' : ''}`}
                role="dialog"
                style={{
                    visibility: isOpen ? 'visible' : 'hidden',
                    display: isOpen ? 'block' : 'none',
                    opacity: isOpen ? 1 : 0,
                }}
            >
                <div className="filters__listings filters__listings--cap-height">
                    <div className="h5 u-margin-t--none u-margin-l--sm">Price</div>
                    <div className="price-inputs">
                        <input
                            type="number"
                            placeholder="Min Price"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                        />
                        <input
                            type="number"
                            placeholder="Max Price"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                        />
                    </div>
                </div>
                <div className="filters__actions">
                    <button
                        className="js-dropdown-close btn btn--sm u-margin-l--sm"
                        onClick={() => {
                            handlePriceChange(); // Trigger the onChange only when the "Done" button is clicked
                            setIsOpen(false);
                        }}
                    >
                        Done
                    </button>
                    <button className="js-clear-filter" onClick={clearSelection}>
                        <span className="u-margin-l--xl link">Clear</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MultiInputPrice;
