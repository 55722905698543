import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the PhoneInput styles
import { convertPhoneNumber, CountryCodeWithString } from '../../utils/phoneUtils';

import api from '../../api'; // Adjust the path based on your project structure

const ResetPassword = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        otp: '',
        newPassword: '',
    });

    const [formErrors, setFormErrors] = useState({
        email: '',
        phone: '',
        otp: '',
        newPassword: '',
    });

    const [step, setStep] = useState(1);
    const [userUUID, setUserUUID] = useState('');
    const [usePhone, setUsePhone] = useState(false);

    const handleChange = (value, country, e, formattedValue) => {
        const phoneNumber = value.replace(/^\+/, '');
        const countryCode = phoneNumber.slice(0, 2);
        const numberWithoutCountryCode = phoneNumber.slice(2);
        const formattedPhone = `${countryCode}-${numberWithoutCountryCode}`;
        setFormData({
            ...formData,
            phone: formattedPhone, // update phone value directly from PhoneInput
        });

        setFormErrors({
            ...formErrors,
            phone: '',
        });
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        setFormErrors({
            ...formErrors,
            [e.target.name]: '',
        });
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validateForm = () => {
        let valid = true;
        let errors = {};

        if (step === 1) {
            if (!usePhone) {
                if (!validateEmail(formData.email)) {
                    errors.email = 'Invalid email format';
                    valid = false;
                }
            }
            if (usePhone && !formData.phone) {
                errors.phone = 'Phone number is required';
                valid = false;
            }
        }

        if (step === 2) {
            if (!formData.otp) {
                errors.otp = 'OTP is required';
                valid = false;
            }
        }

        if (step === 3) {
            if (!formData.newPassword || formData.newPassword.length < 4) {
                errors.newPassword = 'Password must be at least 4 characters';
                valid = false;
            }
        }

        setFormErrors(errors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            if (step === 1) {
                const username = formData.email || (formData.phone ? convertPhoneNumber(formData.phone) : '');
                // Check if the user exists
                const checkUserResponse = await api.post('/user/check_user', {
                    username: username,
                });

                if (checkUserResponse.data.status === 200) {

                    // Forgot Password Step
                    const response = await api.post('/user/forgot_password', {
                        email: !usePhone ? formData.email : '',
                        phone: usePhone ? formData.phone : '',
                    });

                    const data = await response.data;
                    if (response.status === 200) {
                        setUserUUID(data.message.user_uuid || '');
                        setError(null);
                        setStep(2);
                    } else {
                        alert(data.message || 'Error sending reset password request');
                    }
                } else if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                    // User does not exist, show an error message
                    setError("Your account doesn't exist with this phone or email. Please sign up first, then try again.");
                } else {
                    throw new Error('Unexpected response from server.');
                }
            } else if (step === 2) {
                // Confirm OTP Step
                const response = await api.post('/user/confirm_otp', {
                    otp: formData.otp,
                    email: !usePhone ? formData.email : '',
                    phone: usePhone ? formData.phone : '',
                    user_uuid: userUUID,
                });

                const data = await response.data;
                if (data.status === 200) {
                    setStep(3);
                } else {
                    alert(data.message || 'Invalid OTP');
                }
            } else if (step === 3) {
                // Change Password Step
                const response = await api.post('/user/change_password_confirm', {
                    new_password: formData.newPassword,
                    user_uuid: userUUID,
                });

                const data = await response.data;
                if (response.status === 200) {
                    alert('Password changed successfully');
                    setFormData({ email: '', phone: '', otp: '', newPassword: '' });
                    navigate('/login');
                    setStep(1);
                    setUsePhone(false);
                } else {
                    alert(data.message || 'Error changing password');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    const handleToggleUsePhone = () => {
        setUsePhone(!usePhone);
        setFormData({
            ...formData,
            email: '',
            phone: '',
        });
        setFormErrors({
            ...formErrors,
            email: '',
            phone: '',
        });
    };

    return (
        <div className="frg-pass" id="wrapper">
            {/* <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" />
            </div> */}
            <div className="forgot-password-block">
                <h3>Account Signup</h3>
                <h4>Reset Your Password</h4>
                <p>
                    Enter the {usePhone ? 'phone number' : 'email address'} associated with your account and we’ll send
                    you a link to reset your password
                </p>
                <form className="auth-form" onSubmit={handleSubmit}>
                    {step === 1 && (
                        <div className="form-group">
                            {!usePhone ? (
                                <>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Enter your email"
                                    />
                                    {formErrors.email && <div className="error">{formErrors.email}</div>}
                                </>
                            ) : (
                                <>
                                    <PhoneInput
                                        country={'us'}
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="Enter your phone number"
                                        inputStyle={{ width: '100%' }}
                                        disabledCountryCode
                                    />
                                    {formErrors.phone && <div className="error">{formErrors.phone}</div>}
                                </>
                            )}
                        </div>
                    )}

                    {step === 2 && (
                        <div className="form-group">
                            <input
                                type="text"
                                id="otp"
                                name="otp"
                                value={formData.otp}
                                onChange={handleInputChange}
                                placeholder="Enter OTP"
                            />
                            {formErrors.otp && <div className="error">{formErrors.otp}</div>}
                        </div>
                    )}

                    {step === 3 && (
                        <div className="form-group">
                            <input
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                value={formData.newPassword}
                                onChange={handleInputChange}
                                placeholder="Enter new password"
                            />
                            {formErrors.newPassword && <div className="error">{formErrors.newPassword}</div>}
                        </div>
                    )}

                    <button type="submit">
                        {step === 1 && 'Reset Password'}
                        {step === 2 && 'Confirm OTP'}
                        {step === 3 && 'Change Password'}
                    </button>

                    {step === 1 && (
                        <p>
                            {usePhone ? (
                                <span>Don’t have access to email? <a href="#!" onClick={handleToggleUsePhone}>Use Email Instead</a></span>
                            ) : (
                                <span>Don’t have access to phone? <a href="#!" onClick={handleToggleUsePhone}>Use Phone Number</a></span>
                            )}
                        </p>
                    )}
                    {error && <div className="error-message">{error}</div>}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
