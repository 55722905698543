import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import api from '../../api'; // Assuming 'api' is a configured instance of Axios or similar
import { useAuth } from '../../context/AuthContext';

const OrderDetails = () => {
    const { orderId } = useParams(); // Extract orderId from URL params
    const [orderDetail, setOrderDetail] = useState(null);
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderDetail = async () => {
            try {
                const api_token = localStorage.getItem('apiToken');
                const response = await api.get('/commerce/get_user_receipts', {
                    params: {
                        api_token: api_token,
                        order_id: orderId,
                    },
                });
                const data = response.data;

                if (response.data.status === 401) {
                    logout();
                    navigate('/home');
                    return;

                } else if (data.receipt_data && data.receipt_data.length > 0) {
                    const receipt = data.receipt_data[0];
                            console.log(receipt,'receipt.item_list');
                    // Group items by store
                    const storeGroups = receipt.item_list.reduce((acc, item) => {
                        if (!acc[item.store_name]) {
                            acc[item.store_name] = {
                                store_name: item.store_name,
                                store_image: item.store_image,
                                items: []
                            };
                        }
                        acc[item.store_name].items.push({
                            product_title: item.product_title,
                            SUI: item.SUI,
                            product_image: item.product_url,
                            quantity: item.quantity,
                            original_price: item.original_total,
                            final_price: item.total,
                            color: item.color_name,
                            size: item.size_name,
                            delivery_date: item.ship_eta,
                            currency_symbol: item.currency_symbol,
                            platform_discount: item.platform_discount,
                        });
                        return acc;
                    }, {});

                    const detailedOrder = {
                        order_id: receipt.order_id,
                        order_date: receipt.order_date,
                        total_price_inc_tax: receipt.total_price_inc_tax,
                        total_tax: receipt.total_tax,
                        total_unit_price: receipt.total_unit_price,
                        total_discount: receipt.total_discount,
                        total_amount_paid: receipt.total_amount_paid,
                        coupon_value: receipt.coupon_value,
                        shipping: receipt.shipping,
                        currency_symbol: receipt.currency_symbol,
                        stores: Object.values(storeGroups)
                    };

                    setOrderDetail(detailedOrder);
                }
            } catch (error) {
                console.error('Error fetching order detail:', error);
            }
        };

        fetchOrderDetail();
    }, [orderId, logout, navigate]);
console.log(orderDetail,'orderDetail')
    return (
        <div className="order-detail container order-details-pg">
            <h2>Order Detail</h2>
            {orderDetail ? (
                <div className="new-orderrow">
                    <div className="order-details">
                        <div className="store-order">
                            <div className="store-section">
                                <div className="order-store">
                                    <ul>
                                        <li>
                                            <strong>Order number: </strong>
                                            <span>{orderDetail.order_id}</span>
                                        </li>
                                        <li>
                                            <span> <strong>Order Date: </strong></span>
                                            <span>
                                                {(() => {
                                                    const date = new Date(orderDetail.order_date);
                                                    const day = date.getDate();
                                                    const month = date.toLocaleString('default', { month: 'long' });
                                                    const year = date.getFullYear();
                                                    return `${day} ${month} ${year}`;
                                                })()}
                                                </span>


                                        </li>
                                    </ul>
                                </div>

                                {orderDetail.stores && orderDetail.stores.length > 0 ? (
                                    orderDetail.stores.map((store, index) => (
                                        <div key={index}>
                                            <div className="top-details store-name">
                                                <h4>
                                                    <strong>Store:</strong> {store.store_name}
                                                </h4>
                                                {/* <img src={store.store_image} alt={store.store_name} className="store-image" /> */}
                                            </div>
                                            {store.items.map((item, itemIndex) => (
                                                <div className="product-row" key={itemIndex}>
                                                       <Link to={`/product/${item.SUI}`}>

                                                    <div className="img-block">
                                                        <img src={item.product_image} alt={item.product_title} />
                                                    </div>
                                                    </Link>
                                                    <div className="product-details">
                                                        <div className="pro-name">
                                                            <h4> <Link to={`/product/${item.SUI}`}>{item.product_title}</Link></h4>
                                                        </div>
                                                        <div className="color">
                                                            <span>Colour:</span> {item.color || 'N/A'}
                                                        </div>
                                                        <div className="size">
                                                            <span>Size:</span> {item.size || 'N/A'}
                                                        </div>
                                                        <div className="quantity size">
                                                            <span>Qty:</span> {item.quantity}
                                                        </div>
                                                      
                                                         <div className="description content">
                                                        <div className="price-value">
                                                            {item.original_price !== item.final_price ? (
                                                                <>
                                                                    <span className="strike_price">
                                                                        {`${item.currency_symbol}${item.original_price}`}
                                                                    </span>
                                                                    <span className="final_price">
                                                                        {`${item.currency_symbol}${item.final_price}`}
                                                                    </span>
                                                                    <span className="discount">
                                                                        {`${item.platform_discount}% off`}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span className="final_price">
                                                                    {`${item.currency_symbol}${item.final_price}`}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <p>No stores found for this order.</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="items-details">
                        <table>
                            <thead>
                                <tr>
                                    <th align="left">Item Total Price (excluding tax)</th>
                                    <th align="left">
                                        {orderDetail.currency_symbol}
                                        {orderDetail.total_unit_price}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr>
                                    <td>Taxes</td>
                                    <td>
                                        {orderDetail.currency_symbol}
                                        {orderDetail.total_tax}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total (Including tax)</td>
                                    <td>
                                        {orderDetail.currency_symbol}
                                        {orderDetail.total_price_inc_tax}
                                    </td>
                                </tr>
                               
                                {orderDetail.shipping > 0 && (
  <tr>
    <td>Shipping</td>
    <td>
      {orderDetail.currency_symbol}
      {orderDetail.shipping}
    </td>
  </tr>
)}
                                {orderDetail.coupon_value > 0 && (
  <tr>
    <td>Coupon Applied</td>
    <td>
      -{orderDetail.currency_symbol}
      {orderDetail.coupon_value}
    </td>
  </tr>
)}

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total paid</td>
                                    <td>
                                        {orderDetail.currency_symbol}
                                        {orderDetail.total_amount_paid}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default OrderDetails;
